import React from 'react'
import { aboutData } from '../../utils/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Abouthome = () => {
  return (
    <>{aboutData.map(({ques, ans})=>{
        return <div className="sec1 ">
        <div className="top">
          <p className="px-3 py-2 text-2xl bg-ashes rounded-xl ">
            <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" /> {ques}
          </p>
        </div>
        <div className="desc">
          <p className="px-6 py-2 text-lg md:text-xl">
        {ans}
          </p>
        </div>
      </div>
    }) }</>
  )
}

export default Abouthome