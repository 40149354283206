import React, { useEffect, useState,useContext } from "react";
import Inputfield from "../../components/Inputfield";
import Inputtitle from "../../components/Inputtitle";
import api from "../../../api/baseapi";
import { useForm } from "react-hook-form";
import {
  addstringtoarray,
  handleAddClick,
  handleRemoveClick,
  hookAddinput,
} from "../../hooks/hookAddinput";
import Textarea from "../../components/Textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Context from "../../../Context";
import Texttahqeeq from "../../components/Texttahqeeq";
import Testt from "../../components/Testt";


const AddNarration = () => {
  const {textArray} = useContext(Context)
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text:[
        {arabic:{sanad:"",
        matan:""},
      
      bangla:"",
      takhrij:[]}
  ]
    },
  });

  

    const dataa = {
        id:1,
        title: {arabic:'',bangla:'সালাতে বুকের উপর হাত বাঁধা'},
        isRewyat: {isMaqbool:true, isMardood:false},
        keywords:['হাত বাধাঁ','সালাত'],
        grade:'Sahih',
        gradeType: {isSahih:true},
        type:{isMarfo:true,isMawqof:false,isMaqtu:false,isQaul:false},
        text:{arabic:{sanad:'this is sanad',matan:'this is matan'},bangla:{sanad:'this is bangla sanad',matan:'this is bangla matan'}},
        takhrij:['Bukhari','Muslim','Tirmizi'],
        narrator:'Hulub At Taye',
        narrators:['other sahaba','other sahaba'],
        sanadas:[{imam:'imam name',sanad:'arabic sanad',arabic:'this is arabic matan',bangla:'this is bangla matan',ref:'this is ref'}],
        aslikehadith:[{imam:'imam name',sanad:'arabic sanad',arabic:'this is arabic matan',bangla:'this is bangla matan',ref:'this is ref'}],
        who_doesnt_accepted:[{name:'this is name',arabic:'arabic text',bangla:'banglatext',ref:'this is ref'}],
        who_accepted:[{name:'this is name',arabic:'arabic text',bangla:'banglatext',ref:'this is ref'}],
        sanad_desct:'this is snad desc',
        matan_desc:'this is matan desc',
        watch_others:['links','links'],
        d_link:'this is downlink',
        slug:'this is slug',
        hit_count:3     
    }

    const [isRewyat, setIsRewyat] = useState({ isMaqbool: false, isMardood: false });
    const [keywords, setKeywords] = useState([]);
    const [category, setCategory] = useState([]);

    

    const [text, setText] = useState({
      arabic:{
        sanad:"daif",
        matan:"haza matanun"
      },
      bangla:"this is bangla"
    })
    const [takhrij, setTakhrij] = useState(['farhan','shakil'])
    const [narrators, setNarrators] = useState([])
    const [sanads, setsanads] = useState([
      {
        sanad:"haza sanadun",
        imam:"haza imamun",
        arabic:"haza arabic",
        bangla:"haza bangla",
        ref:"haza ref"
      }
    ])
    const [aslikehadith, setAslikehadith] = useState([
      {
        sanad:"haza sanadun",
        imam:"haza imamun",
        arabic:"haza arabic",
        bangla:"haza bangla",
        ref:"haza ref"
      }
    ])

const [sanad_desc, setSanad_desc] = useState("")
const [matan_desc, setMatan_desc] = useState("")
const [watch_others, setWatch_others] = useState([])
const [d_link, setD_link] = useState("download")
const [slug, setSlug] = useState("")
const [categorylist,setCategoryList] = useState([])
  // const {isRajul_type} = id

  // const [idt, setidt] = useState(id)
  const [lidd, setLidd] = useState(0)
  useEffect(() => {
    api.get('/tahqeeqs').then((res) => setLidd(res.data.tahqeeqCount));
    api.get("/categorys").then((res) => setCategoryList(res.data.categorys));
    

  }, [])

  useEffect(() => {
    reset(lidd);

    
  }, []);

  

  
  

  const [name, setname] = useState({ arabic: "old name", bangla: "old name" });
  const [f_name, setf_name] = useState({ arabic: "sefsef", bangla: "sefesf" });
  const [q_name, setq_name] = useState("sefsef");
  const [bd_date, setbd_date] = useState({ birth: 196, death: 256 });
  const [isStarred, setisStarred] = useState(false);
  const [tabqa, settabqa] = useState(0);
  const [isSahabi, setisSahabi] = useState(false);
  const [isRajul_type, setisRajulType] = useState({
    isMuaddal: true,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  });
  const [gradeType,setGradeType] = useState({
    isSahih:false,
        isHasan:false,
        isDaif:false,
        isMauzu:false,
  })


  const [isMudallis, setisMudallis] = useState(true);
  const [isMukhtalit, setisMukhtalit] = useState(true);
  // const [isFissittah, setisFissittah] = useState({
  //   bukhari: false,
  //   muslim: false,
  //   tirmizi: false,
  //   abuDawud: false,
  //   nasaee: false,
  //   ibnMajah: false,
  // });
  const [type, setType] = useState({isMarfo:false,isMawqof:false,isMaqtu:false,isQaul:false})
  const [books, setbooks] = useState([{ name: "sdfsdf", link: "sefsef" },{ name: "newnew", link: "sefsef" },{ name: "goodbook", link: "sefsef" }]);
  const [teachers, setteachers] = useState(["sefsefsfe", "sefsef"]);
  const [students, setstudents] = useState(["sefsefsfe", "sefsef"]);
  const [who_accepted, setWho_accepted] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);
  const [who_doesnt_accepted, setWho_doesnt_accepted] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);
  const [problems,setProblems] = useState([{
    problem:'',
    ref:'',
    link:''
}])
  const [description, setdescription] = useState("sdfsfs");
  const [special_desc, setspecial_desc] = useState("sdfsdf");


  // Setting Strings for input
  const [takhrijstring, settakhrijstring] = useState([]);
  const [studentstring, setstudentstring] = useState([]);

  const handleChangee = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const newfun = (state, setState, event, index, x) => {
    const { name, value, id } = event.target;
    // const new = id
    // const far = id
    const list = [...state];
    // list[index][name][id] = value;
    //  const item = {...x, ["quote"[event.target.name]] : event.target.value, }
    const item = {
      ...x,
      quote: { ...x.quote, [event.target.name]: event.target.value },
    };

    list[index] = item;
    setState(list);
  };
  // setJarahlist({
  //   ...jarahlist,
  //   quote: {
  //     ...jarahlist.quote,
  //     bangla: e.target.value,
  //   },
  // })


  const handleChange = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const objOfBtns = (e, state, setstate, val) => {
    setstate({
      ...state,
      [e.target.name]: val,
    });
  };
  const [first, setfirst] = useState([])
  const addbtn = (i) => {
    const check = category.every(item => {
        return item !== i
    })
    if (check) {
        category.push(i)
    }


    setCategory([...category])
    // console.log(displayarray)
    console.log("category data",category)

}

const dltcateg = (idd)=>{
  if(category.includes(idd)){
      const ar = category.indexOf(idd);
      category.splice(ar,1);
      setCategory([...category])

  }
}


    const handlecat = (categories)=>{
    let myCat =[];
    for(let category of categories){
      myCat.push(
        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />

        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />
        <div>
          
    <div className='bg-[#e8ff1b] flex'>
        
          {category.parentId ? 
          <div className="flex items-center justify-between w-full p-[2px_16px] text-white border-b bg-[#bfbfbf]">
            <p className='accordion-title whitespace-nowrap cursor-pointer' onClick={() => addbtn({_id:category._id, name:category.name, slug:category.slug})} >{category.name}</p>
            </div>
            :null 
            }
        

        <div>
            <div className="accordion-body">
              { category.children.length > 0 && <div>
                  
                      { <p className=" ulul" >{handlecat(category.children)}</p>}
                    {/* {category.children.length > 0 ?(<ul>{handlecat(category.children):null} */}
                

                    {/* <AccordionBody>
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet.
                        </div>
                    </AccordionBody> */}
                </div>}
                
            </div>
        </div>
    </div>
</div>
        // <li key={category.name}>
        //   {category.name}
        //   {category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null}
        // </li>
    
      )
    }
    return myCat;
  }
let result = category.map((item) => ({_id: item._id}))
console.log({result})


  const rajuldata = {
    
    isRewyat,
    category: category.map((item) => (item._id.toString())),
    
    gradeType,
    type,
    takhrij,
    who_accepted,
    who_doesnt_accepted,
    problems,
    text:textArray



  };

  const addRajul = () => {
    api.post("/rajul/add", rajuldata);
    console.log("alldata new", rajuldata);
  };

  const newsubmit = (data,e) => {
    e.preventDefault();
    // ...data,
    const neww = { ...data, ...rajuldata };
    api.post("/tahqeeq/add", neww,{withCredentials:true}).then((res)=> toast.success("submit success"));
    // console.log(rajuldata, 'hello new')
    // console.log('data', data )
    // console.log('errors', errors)
    // console.log('testname', name)
    console.log({data}
    )
    console.log("testname", neww);
  };
  const onChange = (event) => {
    console.log("change", !event.target.value);
  };

  return (
    <form className="" onSubmit={handleSubmit(newsubmit)}>
      
      <div className="container px-10 m-auto">
        <div className="h-[80px]">
        <div className="right-0 flex justify-end w-full h-12 mb-9">
          <button type="submit" className="bg-white hover:border-2 hover:border-[#fbff29] py-0 px-[32px] rounded-[50px] text-xl font-semibold text-[#4a4a4a] shadow-[-1px_1px_3px_0px_#d9d9d9]">
            সাবমিট করুন
          </button>

          {/* <input type="submit" className="bg-slate-600" value="সাবমিট করুন" /> */}
        </div>
      </div>

      
      
        {/* <p>{id && rajul.id - 1}</p> */}
        {/* <input
                    
                    value={id}
                    onChange={(e)=>setid(e.target.value)}
                    placeholder="Id"
                  /> */}
        {/* <input name="firstName" type="text" {...register('firstName')} className="form-control" /> */}

        <div className="id ">
        <Inputtitle title={"আইডি নং "} />

        <div className="flex flex-col items-center gap-4 mb-5 md:flex-row">

          <p className="w-[2] bg-[#d8e7e6] py-[2px] px-[17px] text-[#545454] font-bold rounded-[4px] shadow-[1px_1px_3px_-1px_#ababab] ">পূর্বের আইডি নং: 0{lidd}</p>

<div className="w-[200px]">
        <Inputfield
          placeholder={"id"}
          name="id"
          v_hook={register("id", {
            required: true,
            value:lidd + 1,
            valueAsNumber: true,
            pattern:{
              value: /^(0|[1-9]\d*)(\.\d+)?$/
           },
          })}
          newstyle={
            errors.id && errors.id ? "border-[red] border-b-[3px]" : null
          }
        /></div>
        </div>
        </div>
        

        <div className="mb-5 single_input nameInput">
          <Inputtitle title={"শিরোনাম"} />
          <div className="flex flex-col gap-4 md:flex-row">
            <Inputfield
              placeholder={"আরবী"}
              name="arabic"
              v_hook={register("title.arabic", {
                required: true,
                // value: name.arabic || "",
              })}
              newstyle={
                errors.name && errors.title.arabic
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />

            <Inputfield
              placeholder={"বাংলা"}
              name="bangla"
              v_hook={register("title.bangla", {
                required: true,
                // value: name.bangla || "",
              })}
              newstyle={
                errors.name && errors.title.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />
          </div>
        </div>

        {/* <div className="mb-5 single_input Famous nameInput">
          <Inputtitle title={"বর্ণনাটির ইবারত"} />
          <div className="flex flex-col gap-4">
            <Textarea
              placeholder={"সনদ আরবী"}
              name="arabic"
              rows={5}
              v_hook={register("text.arabic.sanad", {
                required: true,
                // value: f_name.arabic || "",
              })}
              newstyle={errors.arabic ? "border-[red] border-b-[3px]" : null}
            />
            <Textarea
              placeholder={"মতন আরবী"}
              name="bangla"
              v_hook={register("text.arabic.matan", {
                required: true,
                // value: f_name.bangla || "",
              })}
              newstyle={
                errors.f_name && errors.f_name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
              // fun={(e) => handleChange(e, f_name, setf_name)}
            />
            <Textarea
              placeholder={"মতন বাংলা"}
              name="bangla"
              v_hook={register("text.bangla", {
                required: true,
                // value: f_name.bangla || "",
              })}
              newstyle={
                errors.f_name && errors.f_name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
              // fun={(e) => handleChange(e, f_name, setf_name)}
            />
          </div>
        </div> */}
        <Texttahqeeq />
        {/* <Testt /> */}

        <div className="mb-5 single_input">
          <div className="flex flex-col gap-4 md:flex-row">
            {/* <div className="takhrij">
              <Inputtitle title={"তাখরীজ"} />
              <div className="div students">
            <Inputfield
              placeholder={"বই এর নাম"}
              fun={(e) => settakhrijstring(e.target.value)}
              value={takhrijstring}
            />

            <button
            type="button"
            className=" bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
              onClick={() =>
                takhrijstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      takhrijstring,
                      settakhrijstring,
                      takhrij,
                      setTakhrij
                    )
              }
            >
              add input
            </button>

            {takhrij.map((x, i) => {
              return (
                <div key={i} className="my-3 box first-letter">
                  <p>{x}</p>
                  <div className="flex flex-col md:flex-row "></div>

                  <div className="btn-box">
                    {takhrij.length !== 0 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(takhrij, setTakhrij, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

            </div> */}

            <div className="narrator">
              <Inputtitle title={"বর্ণনাকারী"} />
              <div className="flex">
                <Inputfield
                  placeholder={"বর্ণনাকারীর নাম"}
                  name="narrator"
                  v_hook={register("narrator", {
                    // value:q_name || ""
                  })}
                  newstyle={
                    errors.narrator && errors.narrator
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
              </div>
            </div>
            <div className="categorry flex-[1]">
              <Inputtitle title={"ক্যাটেগরি"} />

              <div className="category shadow-[0px_1px_6px_0px_#ededed] h-fit">
      
      { categorylist.length > 0 && <p className="flex cat">{handlecat(categorylist)}</p> }
      <div className='flex flex-wrap gap-3 justify-evenly bg-[#ffffff] rounded-[0px_0px_5px_5px] p-[0px_10px]'>
            
            {category.map((i) => {
                return <p onClick={()=>dltcateg(i)} ><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> {i.name}</p>
                })}
        </div>

      </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 hukums md:flex md:flex-row">
          <div>
            <Inputtitle title={"গ্রহণযোগ্যতা"} />
            <div className="flex">
              {gradeType &&
                Object.entries(gradeType).map(([key, val], i) => (
                  <button
                  type="button"
                    className={`${
                      val ? "bg-[#a9f7ff]" : "bg-slate-400"
                    } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                    name={key}
                    onClick={(e) =>
                      objOfBtns(e, gradeType, setGradeType, !val)
                    }
                    key={i}
                  >
                    {key === "isSahih" && <>সহীহ</>}
                    {key === "isHasan" && <>হাসান</>}
                    {key === "isDaif" && <>যঈফ</>}
                    {key === "isMauzu" && <>বানোয়াট</>}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <Inputtitle title={"বর্ণনার ধরন"} />
            <div className="flex flex-wrap">
              {Object.entries(type).map(([key, val], i) => (
                <button
                type="button"
                  className={`${
                    val ? "bg-[#a9f7ff]" : "bg-slate-400"
                  } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                  name={key}
                  onClick={(e) =>
                    objOfBtns(e, type, setType, !val)
                  }
                  key={i}
                >
                  {key === "isMarfo" && <>মারফূ</>}
                  {key === "isMawqof" && <>মাওকূফ</>}
                  {key === "isMaqtu" && <>মাকতূ</>}
                  {key === "isQaul" && <>ক্বওল</>}

                </button>
              ))}
            </div>
          </div>
          <div>
            <Inputtitle title={"বর্ণনার ধরন"} />
            <div className="flex flex-wrap">
              {Object.entries(isRewyat).map(([key, val], i) => (
                <button
                type="button"
                  className={`${
                    val ? "bg-[#a9f7ff]" : "bg-slate-400"
                  } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                  name={key}
                  onClick={(e) =>
                    objOfBtns(e, isRewyat, setIsRewyat, !val)
                  }
                  key={i}
                >
                  {key === "isMaqbool" && <>মাকবুল</>}
                  {key === "isMardood" && <>মারদূদ</>}


                </button>
              ))}
            </div>
          </div>
  
          
        </div>

        <div className="mb-5 string hukum">
          <div className="hukum">
            <Inputtitle title={"হুকুম"} />
            {/* <Inputfield
              placeholder={"হুকুম"}
              fun={(e) => setgrade(e.target.value)}
            /> */}
            <Inputfield
                  placeholder={"হুকুম"}
                  name="grade"
                  v_hook={register("grade", {
                    
                  })}
                  newstyle={
                    errors.grade && errors.grade
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
          </div>
        </div>



        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"যারা গ্রহনযোগ্য আখ্যা দিয়েছেন:"} />

            {who_accepted.map((x, i) => {
              return (
                <div key={i} className="box first-letter ">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                      // fun={(e) => {newfun(tadeellist, setWho_accepted, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                      // fun={(e) => {newfun(tadeellist, setWho_accepted, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(tadeellist, setWho_accepted, e, i)}
                      fun={(e) => {
                        newfun(who_accepted, setWho_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(tadeellist, setWho_accepted, e, i)}
                      fun={(e) => {
                        newfun(who_accepted, setWho_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(tadeellist, setWho_accepted, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {who_accepted.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(who_accepted, setWho_accepted, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {who_accepted.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(who_accepted, setWho_accepted, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"যে ইমামগণ জারাহ করেছেন"} />
            {who_doesnt_accepted.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(who_doesnt_accepted, setWho_doesnt_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(who_doesnt_accepted, setWho_doesnt_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {who_doesnt_accepted.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(who_doesnt_accepted, setWho_doesnt_accepted, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {who_doesnt_accepted.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(who_doesnt_accepted, setWho_doesnt_accepted, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"সনদের সমস্যাগুলো"} />
            {problems.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"সমস্যা"}
                      name="problem"
                      value={x.problem}
                      fun={(e) => hookAddinput(problems, setProblems, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      name="ref"
                      value={x.ref}
                      fun={(e) => hookAddinput(problems, setProblems, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"লিংক"}
                      value={x.link}
                      name="link"
                      fun={(e) => hookAddinput(problems, setProblems, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {problems.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(problems, setProblems, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {problems.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(problems, setProblems, {
                            problem:'',
                            ref:'',
                            link:''
                        })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        


        <div className="mb-5 download link">
          <Inputtitle title={"ডাউনলোড লিংক"} />
          <Inputfield placeholder={"ডাউনলোড লিংক"} name="d_link"
              v_hook={register("d_link", {
               
                // value: f_name.bangla || "",
              })} />
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে বিস্তারিত আলোচনা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
     
    "

              name="description"
              {...register("description", {
               
                // value: f_name.bangla || "",
              })}
              
    
    ></textarea>
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে কিছু বিশেষ কথা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
      
    "
    name="description"
    {...register("special_desc", {
     
      // value: f_name.bangla || "",
    })}
    
    ></textarea>
        </div>
      </div>
    </form>
  );
};

export default AddNarration;
