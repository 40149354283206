import Container from "../../components/layout/Container";
import Hero from "../../components/home/Hero";
import Featurestable from "../../components/home/Featurestable";
import Abouthome from "../../components/home/Abouthome";
import FetchedData from "../../components/home/FetchedData";
import Banner from "../../components/home/Banner";

const Home = () => {
  
  return (
    <Container>
      <Hero />

      <div className="difference text-[16px] md:text-[20px]">
        <Banner />
        <Featurestable />
      </div>
      <div className="description p-2  md:p-4 shadow-[1px_4px_5px_2px_#f1f1f1]">
        {/* <FetchedData /> */}
            <Abouthome />

      </div>
    </Container>
  );
};

export default Home;
