import React from 'react'
import { handleAddClick, handleRemoveClick, hookAddinput, newfun } from '../../hooks/hookAddinput';
import Inputfield from '../Inputfield';
import Textarea from '../Textarea';

const Qaullist = ({array, setArray,title}) => {
  return (
    <><div className="mb-5 tadeellistcontainer">
    <div className="div">
      <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
        {title}:
      </p>{array.map((x, i) => {
        return (

          <div key={i} className="box first-letter">
            <div className="flex flex-col md:flex-row">
              <Inputfield
                placeholder={"ইমামের নাম"}
                name="name"
                value={x.name}
                fun={(e) => hookAddinput(array, setArray , e, i)}
                // fun={(e) => {newfun(array, setArray , e, i,x)}}
              />
              <Inputfield
                placeholder={"মৃত্যু তারিখ"}
                name="deathYear"
                value={x.deathYear}
                fun={(e) => hookAddinput(array, setArray , e, i)}
                // fun={(e) => {newfun(array, setArray , e, i,x)}}
              />
              </div>
              

              <Textarea
                placeholder={"arabic বক্তব্য"}
                value={x.quote.arabic}
                id="arabic"
                name="arabic"
                newstyle={'text-right'}
                rows={10}
                // fun={(e) => hookAddinput(array, setArray , e, i)}
                fun={(e) => {
                  newfun(array, setArray , e, i, x);
                  // setArray (
                  //   ...array,
                  //   ...x,
                  //   quote: {
                  //     ...x.quote,
                  //     arabic: e.target.value,
                  //   },
                  // );
                }}
              />
              <Inputfield
                placeholder={"bangla বক্তব্য"}
                value={x.quote.bangla}
                id="bangla"
                name="bangla"
                // fun={(e) => hookAddinput(array, setArray , e, i)}
                fun={(e) => {
                  newfun(array, setArray , e, i, x);
                  // setArray (
                  //   ...array,
                  //   ...x,
                  //   quote: {
                  //     ...x.quote,
                  //     arabic: e.target.value,
                  //   },
                  // );
                }}
              />
              <div className="flex flex-col md:flex-row">
              {/* <Inputfield
              placeholder={"bangla বক্তব্য"}
              value={x.quote.bangla}
              name="quote"
              fun={(e) => hookAddinput(array, setArray , e, i)}
            /> */}

              <Inputfield
                placeholder={"রেফারেন্স"}
                value={x.ref}
                name="ref"
                fun={(e) => hookAddinput(array, setArray , e, i)}
              />
              <Inputfield
                placeholder={"মান"}
                value={x.grade}
                name="grade"
                fun={(e) => hookAddinput(array, setArray , e, i)}
              />
            </div>

            <div className="btn-box">
              {array.length !== 1 && (
                <button
                  className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                  onClick={() =>
                    handleRemoveClick(array, setArray , i)
                  }
                >
                  Remove
                </button>
              )}
              {array.length - 1 === i && (
                <button
                  className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                  onClick={() =>
                    handleAddClick(array, setArray , {
                      name: "",
                      quote: { arabic: "", bangla: "" },
                      ref: "",
                      grade: "",
                    })
                  }
                >
                  Add
                </button>
              )}
            </div>
          </div>
        );

      })}
      </div>
      </div>
      </>
  )
}

export default Qaullist