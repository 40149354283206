
export const hookAddinput = (state, setState, event, index) => {
  const { name, value } = event.target;
  const list = [...state];
  list[index][name] = value;
  setState(list);
}

export const handleAddClick = (state, setstate,obj) => {
  setstate([...state, obj]);
};


export const handleRemoveClick = (state,setstate,index) => {
  const list = [...state];
  list.splice(index, 1);
  setstate(list)
}
export const addstringtoarray = (i,state, setstate) => {
  const check = state.every(item => {
      return item !== i
  })
  if (check) {
      state.push(i)
  }


  setstate([...state])


}
export const newfun = (state, setState, event, index, x) => {
  const { name, value, id } = event.target;
  // const new = id
  // const far = id
  const list = [...state];
  // list[index][name][id] = value;
  //  const item = {...x, ["quote"[event.target.name]] : event.target.value, }
  const item = {
    ...x,
    quote: { ...x.quote, [event.target.name]: event.target.value },
  };

  list[index] = item;
  setState(list);
};

const dltstringfromarray = (idd,state, setstate)=>{
  if(state.includes(idd)){
      const ar = state.indexOf(idd);
      state.splice(ar,1);
      setstate([...state])

  }
}