import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../src/api/baseapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

function Bookdtls() {
  const [tahqeeqat, settahqeeq] = useState([]);
  const [loader, setloader] = useState(true);
  const { tahqeeqs } = tahqeeqat;

  useEffect(() => {
    api.get("/tahqeeqs").then((res) => settahqeeq(res.data));
    setloader(false);
  }, []);
  console.log("rijals", tahqeeqs);

  const item = {
    id: 4,
    title:'সহীহুল বুখারী',
    author:{avatar:'',
name:'ইমাম বুখারী'},
    book_imgurl:[''],
    category:['হাদীস গ্রন্থ'],
    type: {
      isMarfo: true,
      isMawqof: false,
      isMaqto:false,
      isQaul: false,
    },


  };
  return <div>
    <div className='mb-5 max-w-[900px] m-auto shadow-[2px_2px_5px_0px_#ededed] flex  p-3 text-[#565656]'>
        <div className="b_img">
        <img src="https://dnc.techtunes.io/tDrive/tuner/samuraixbd/230300/Sohih-Bukhari-Taohid.jpg"></img>
            
        </div>
        <div className="b_dtls">
            <div className="b_title">
                <p>{item.title}</p>
                <p>by: {item.author.name}</p>
                <p>category: {item.category.map((item)=> <span>{item}</span>)}</p>
            </div>

            <div className="flex btns">
                <button className="read"> <FontAwesomeIcon
              className="text-[#727272] text-[1rem] "
              icon={faStar}
            />Read</button>
                <button className="addtocard"><FontAwesomeIcon icon={faCartShopping} />Add to Cart</button>
            </div>
            
        </div>
       
      

    </div>

    
    { tahqeeqs && tahqeeqs.map((item,index)=> 
    <div className={`TahqeeqCard mb-5 max-w-[700px] m-auto shadow-[2px_2px_5px_0px_#ededed] border-b border-l-8 p-3 text-[#565656] ${item.isRewyat.isMaqbool &&'border-l-[#13ffeb]' || item.isRewyat.isMardood &&'border-l-[#ff320e]'}`}>
      <div className="flex justify-between top">
      <p className="id_no w-fit font-[kalpurushansi] text-2xl md:text-3xl ">#{item.id <10 && 0}{item.id}</p>
        <div className="flex gap-4 tags">
          {item.isRewyat.isMaqbool && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাকবুল</p>}
          {item.isRewyat.isMardood && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মারদূদ</p>}
          {item.type.isMarfo && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মারফূ’</p>}
          {item.type.isMawqof && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাউকুফ</p>}
          {item.type.isMaqto && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাকতু</p>}
          {item.type.isQaul && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">ক্বওল</p>}
        

        </div>
      </div>

      <div className="title">
        <p className=" md:text-2xl">শিরোনাম: {item.title.bangla}</p>
        {/* <div className="flex category">{item.category.map((i)=><p> > {i}  </p>)}</div> */}

      </div>
      <div className="mt-3 btns">
        <div className="edit">
        <Link className="bg-[#29cd9f] p-[6px_23px] text-white rounded-sm text-[1.1rem] font-[monospace] font-bold" to={`/tahqeeqedit/${item._id}`}>Edit Tahqeeq</Link>

        </div>
      </div>

    </div>)}
    
  </div>;
}

export default Bookdtls;
