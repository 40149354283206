import React from 'react'
import splash from '../img/splash.png'

const Splash = () => {
  return (
    <div>
        <ul class="splash fixed w-[100vw] hw-[100vh] top-0 left-0 m-0 p-0 overflow-hidden bg-themee">
        <div className='splashimage splash bg-themee h-[100vh] justify-center w-full flex items-center'>
    <img className='h-[45%]' src={splash}></img>
    </div>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
</ul>
    

    
  </div>
  )
}

export default Splash