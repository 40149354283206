import React from 'react'

const Pagename = ({name}) => {
  return (
    <div><div className="relative headtitle ">
    <p className="text-2xl border-l-[11px] font-semibold border-[#696969] p-[10px]">পাতা: {name}</p>

  </div></div>
  )
}

export default Pagename