import React from 'react'

const Container = ({children}) => {
  return (
    <div
    className=" xl:max-w-[1280px] max-w-[1620px] 2xl:max-w-[1920px]
    mx-auto
    md:px-2
    px-4
    "
    >{children}</div>
    // p-2  md:p-4 shadow-[1px_4px_5px_2px_#f1f1f1]
  )
  
}

export default Container