import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Cardbtn from '../Cardbtn'

const TahqeeqCard = ({item}) => {
  return (
    <div className={`TahqeeqCard mb-5 m-auto shadow-[-1px_0px_6px_2px_#f3f3f3] rounded-[3px] border-l-[18px] p-3 text-normaltextcolor ${item.isRewyat.isMaqbool &&'border-l-[#13ffeb]' || item.isRewyat.isMardood &&'border-l-reddish'}`}>
    <div className="flex justify-between top">
    <p className="id_no  font-[kalpurushansi] text-2xl md:text-3xl relative flex gap-[6px]  w-[50px] ">

      {/* <img className="octagon  w-[50px]" src= {octagon} alt="octagon" />
       */}
       <FontAwesomeIcon  icon="fa-solid fa-pen-to-square" />

     <span className='idd font-[kalpurushansi] '>
      {item.id <10 && 0}{item.id}
      </span> </p>
      <div className="flex gap-2 tags">
        <Cardbtn value={item.isRewyat.isMardood} color='bg-reddish' text='মারদূদ' />
        <Cardbtn value={item.isRewyat.isMaqbool} color='bg-reddish' text='মাকবুল' />
        
        <Cardbtn value={item.type.isMarfo} color='bg-marfu' text='মারফূ’' />



        {item.type.isMawqof && <p className="bg-[#36dfd7] text-white text-[14px] md:text-[16px] p-[0px_14px] rounded-sm h-fit">মাউকুফ</p>}
        {item.type.isMaqto && <p className="bg-[#36dfd7] text-white text-[14px] md:text-[16px] p-[0px_14px] rounded-sm h-fit">মাকতু</p>}
        {item.type.isQaul && <p className="bg-[#36dfd7] text-white text-[14px] md:text-[16px] p-[0px_14px] rounded-sm h-fit">ক্বওল</p>}
      

      </div>
    </div>

    <div className="title">
    <Link to={`/tahqeeq/${item.id}`}><p className=" hover:underline hover:underline-offset-[6px] text-[1.1rem] md:text-2xl">শিরোনাম: {item.title.bangla}</p></Link>
      {/* <div className="flex category">{item.category.map((i)=><p> > {i}  </p>)}</div> */}

    </div>
    <div className="md:mt-2 flex gap-2 md:gap-5 flex-col md:flex-row">
     <p className='narrat text-xs md:text-[16px] py-[1px] px-2 pl-0 md:pl-0 md:py-1 md:px-2 rounded'><span className='narrator font-bold '>বর্ণনাকারী:</span>  {item.narrator}</p> 

     <div className="categories text-xs md:text-[16px] flex gap-2 flex-wrap">
      {item.category.map((i)=><p className="category bg-[#efefef] w-fit py-[1px] px-2 md:py-1 md:px-2 rounded"><FontAwesomeIcon icon="fa-solid fa-tags" />  {i.name}</p>)}

     </div>
    </div>


  </div>
  )
}

export default TahqeeqCard