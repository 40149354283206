import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { featuresHome } from '../../utils/data'

const Featurestable = () => {
  return (
    <table id="namess">
            
  <tr className='flex'>
    <th className='flex-[0.5] bg-theme'></th>
    <th className='flex-[3] bg-theme'>বিশেষত্ব</th>
    <th className='flex-[1] bg-theme flex justify-center'>আহলে তাহকিক</th>
    <th className='flex-[1] bg-theme flex justify-center'>অন্যান্য</th>
  </tr>
  {featuresHome.map((e,i)=><tr className='flex border-b-2 border-b-[#dfdfdf]'>
    <td className='flex-[0.5] flex justify-center items-center '><div className="div  flex justify-center  items-center h-[50px] w-[50px] rounded-full bg-theme text-white">
    <p className='no   text-center font-[kalpurushansi] '>0{i+1}</p>
        </div></td>

    <td className='flex-[3]'><p>{e.text}</p></td>
    <td className='flex-[1] flex justify-center items-center'>
    <div className="div  flex justify-center items-center h-[30px] w-[30px] rounded-full bg-[#53ff1d]">
    <p className='text-center text-[#1f773c] h-fit w-fit '><FontAwesomeIcon icon="fa-solid fa-check" /></p>
    </div>
        </td>
    <td className='flex-[1] flex justify-center items-center'>
    <div className="div  flex justify-center items-center h-[30px] w-[30px] rounded-full bg-[#ff3100]">
    <p className='text-center text-[#741b1b] h-fit w-fit '><FontAwesomeIcon icon="fa-solid fa-xmark" /></p>
    </div>
        
         </td>
  </tr>)}

  {/* <tr>
    <td>Berglunds snabbköp</td>
    <td>Christina Berglund</td>
    <td>Sweden</td>
    <td>Sweden</td>
  </tr> */}

</table>
  )
}

export default Featurestable