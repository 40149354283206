import React from 'react'

const Filtercounter = ({value, totalCount, filterCount,resultprpage,text}) => {
  return (
    <div className="flex justify-between mt-4 text-[14px] md:text-[16px]">
<div className="total w-fit py-[2px] px-2 rounded-[3px] shadow-[2px_1px_3px_0px_#e9e9e9] flex-wrap flex gap-2 "><p><span className="font-bold"> মোট {text}:</span> <span className="font-[kalpurushansi]">{totalCount <10 && 0}{totalCount}</span> </p>
{value && <p ><span className="font-bold">প্রাপ্ত {text}:</span>  <span className="font-[kalpurushansi]">{filterCount <10 && 0}{filterCount}</span> </p>}</div>
<div className="totalperpage total w-fit py-[2px] px-2 rounded-[3px] shadow-[2px_1px_3px_0px_#e9e9e9] ">
  <p><span className="font-bold">প্রতি পৃষ্ঠায়:</span> <span className="font-[kalpurushansi]">{resultprpage}</span></p>

</div>

        </div>
  )
}

export default Filtercounter