import React, { useState , useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../Context";
import {toast} from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Login = () => {
    const navigate = useNavigate()
    const {login,message,getmyData} = useContext(Context)
    console.log({message})
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const handleLoginForm = async (e) => {
    e.preventDefault();
    if (!email && !password) {
      setErr("Fields are empty");
      return;
    } else if (!email) {
      setErr("Enter email or user name");
      return;
    } else if (!password) {
      setErr("Enter password");
      return;
    } else {
      setErr("");
      // await axios.post(
      //   "http://localhost:4000/api/user/login",
      //   {
      //     email,
      //     password,
      //   },
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     withCredentials: true,
      //   }
      // );
      await login(email,password)
    
    }
  };
  useEffect(()=>{
    if(message==='Logged in successfully'){
      toast.success(message)
      getmyData()
      // navigate("/admin")
    }
    if(message !== 'Logged in successfully'){
      toast.error(message?.response?.data?.error)
    }
  },[message])
  return (
    <div className="w-full h-[100vh]" >
      <img className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1515091943-9d5c0ad475af?ixlib=rb-4.0.3&w=1080&fit=max&q=80&fm=jpg&crop=entropy&cs=tinysrgb" alt="cover" />
      <div className="absolute top-0 w-full h-full backdrop-blur-sm"></div>
      <form
        className="w-[25%] flex flex-col gap-3 mx-auto absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        onSubmit={handleLoginForm}
      >
        <input
          className="pl-3 pr-10 py-4 rounded-t-md outline-none placeholder:text-black bg-[#fbfbfba1]"
          placeholder="Email or userName"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
        />
        <FontAwesomeIcon className="absolute top-5 right-5" icon="fa-solid fa-user" />
        <input
          className="pl-3 pr-10 py-4 rounded-b-md outline-none  placeholder:text-black bg-[#fbfbfba1]"
          placeholder="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <FontAwesomeIcon className="absolute top-[85px] right-5" icon="fa-solid fa-lock" />
        <p className="px-2 text-pink-600 bg-black rounded w-fit">{err}</p>
        <input
          className="h-[40px] rounded bg-green-100 text-green-500"
          type="submit"
          value={"Log in"}
        />
        
      </form>
    </div>
  );
};

export default Login;
