import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Risalah = () => {
  return (
    <div className="description p-1 m-auto mx-4 lg:mx-20 xl:mx-40 md:p-4 shadow-[1px_4px_5px_2px_#f1f1f1]">
            <div className="sec1">
            <div className="top">
                    <p className='text-2xl  py-2 px-3 bg-[#71d3f9] text-white'><FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />  দুঃখিত </p>
                </div>
                <div className="desc">
                <p className='text-lg py-2 px-6 md:text-xl'> পাতাটির কাজ এখনো চলমান।  ‍</p>
                </div>
                
                
            </div>
            
                </div>
  )
}

export default Risalah