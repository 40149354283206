import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { faAward, faLinkSlash, faStar, faXmark, faHeadSideVirus } from "@fortawesome/free-solid-svg-icons";
import api from "../../api/baseapi";
import { Link, useSearchParams } from "react-router-dom";
import Searchitem from '../../components/SearchItem'
import Pagename from '../../components/Pagename'
import Pagination from "react-js-pagination";
import Letter from "../../components/Letter";


const Rijal = () => {
  const [rijal, setrijal] = useState([])
  const [loader, setloader] = useState(true)
  const [searchkey, settSearch] = useState(null);
  const {rajuls,rijalCount,resultPerPage,filteredRijalCount} = rijal;
  const [data, setData] = useState([]);
  // const {rajuls,rijalCount,resultPerPage,filteredRijalCount} = data
  // const [search, setSearch] = useState('')
  // console.log("rijalCount",rijalCount,resultPerPage,filteredRijalCount);
  // const [pageNo, setPageNo] = useState(1)
  let [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const search = searchParams.get("search");
  // const mudallis = searchParams.get("filter");
  const starred = searchParams.get("isStarred");
  const searched = searchParams.get("Search")
  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };
  const count = filteredRijalCount;
  
  console.log("search",searched)
  console.log("filter",filter)


  useEffect(() => {
     async function filterhandler(){
      if (filter !== null && filter === "sahabi") {
        return await api.get(`/rijal?isSahabi=${filter && true }&page=${currentPage}`)
          .then((res) => setrijal(res.data));
      }
      if (filter !== null && filter ==='starred') {
            return await api
              .get(`/rijal?isStarred=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='muaddal') {
            return await api
              .get(`/rijal?isRajul_type.isMuaddal=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='majrooh') {
            return await api
              .get(`/rijal?isRajul_type.isMajrooh=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='majhool') {
            return await api
              .get(`/rijal?isRajul_type.isMajhool=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if ( search !==null) {
            return await api
              .get(`/rijal?keyword=${search}`)
              .then((res) => setrijal(res.data));
          }
          
          else{
            api.get(`/rijal?&page=${currentPage}`).then((res) => setrijal(res.data))
          }
  
    }
    filterhandler()
  
  }, [filter,search,currentPage])



 
  console.log('rijals',rajuls)
  
  const id = 99999;
  const name = "মুহাম্মাদ বিন ইসমাঈল আল-বুখারী";
  const f_name = "ইমাম মুহাম্মাদ বিন ইসমাইল আল-বুখারী";
  const bd_date = { birth: "196", death: "264" };
  const isRajul_type = {
    isMuaddal: true,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  };
  const isStarred = true;
  const isSahabi = true;
  const item ={
    id:5,
    name:{arabic:'محمد بن إسماعيل بن إبراهيم بن المغيرة بن بذدزبة',bangla:'মুহাম্মাদ বিন ইসমাঈল বিন ইবরাহীম বিন আল-মুগিরাহ বিন বাযদাযবাহ'},
    f_name:{arabic:'محمد بن إسماعيل البخاري',bangla:'মুহাম্মাদ বিন ইসমাঈল আল-বুখারী'},
    bd_date: {
      birth: 202,
      death: 256
      },
    isSahabi: true,
    isRajul_type: {
      isMuaddal: true,
      isMajrooh: false,
      isMajhool: false,
      isMixed: false
      },
      isStarred: true,
  }
  return (
    <>
    {!loader? <div className="h-[100vh] w-[100wh] bg-[red]">loader....</div> : 
    <div className="px-2 rijal">
      <Pagename name={"আসমাউর রিজাল"} />

     
     <div className="max-w-[700px] m-auto">
     <div className="Letters">
          <Letter />
        </div>
      
     <div className="flex flex-col items-center gap-4 mt-5 sahabi gap-y-3 md:justify-center md:flex-row ">
      <div className="flex gap-1 btns md:gap-3">
      {/* <p className="relative m-16 group">
  <span>Hover over me</span>
  <span className="absolute -bottom-1  w-full h-1 bg-[#858585] group-hover:w-0 group-hover:transition-all"></span>
  {/* <span className="absolute w-0 h-2 bg-red-400 -bottom-1 right-1/2 group-hover:w-1/2 group-hover:transition-all"></span> */}
{/* </p> */} 
{/* <div className="others"></div> */}

<div className="flex gap-1 sahabi md:gap-3">
<Link className={` p-[1px_17px] text-center ${filter === 'sahabi' ? 'bg-[#dddddd]': 'bg-[#ffffff]'} rounded-sm text-[#303030]  text-[14px] md:text-[18px] hover:bg-[#ffffff] shadow-[0px_1px_3px_0px_#e3e3e3]`} to={`?filter=sahabi`}> <FontAwesomeIcon icon={faAward} /> সাহাবী</Link>

        <Link className={`${filter === 'starred' ? 'bg-[#dddddd]': 'bg-[#ffffff]'}   p-[1px_17px] rounded-sm text-[#303030] text-[14px] md:text-[18px] hover:bg-[#ffffff] shadow-[0px_1px_3px_0px_#e3e3e3]`} to={`?filter=starred`}><FontAwesomeIcon icon={faStar} /> স্টার</Link>

</div>
  
        

        <div className="flex gap-1 mudallismukhtalidd md:gap-3">
          
        <Link className='bg-[#b1fff1] p-[1px_17px] rounded-sm text-[#303030] text-[14px] md:text-[18px] hover:bg-[#ffffff] shadow-[0px_1px_3px_0px_#e3e3e3]'  to={`?filter=mudallis`}><FontAwesomeIcon icon={faLinkSlash} />মুদাল্লিস</Link>

        <Link className='bg-[#b1fff1] p-[1px_17px] rounded-sm text-[#303030] text-[14px] md:text-[18px] hover:bg-[#ffffff] shadow-[0px_1px_3px_0px_#e3e3e3]' to={`?filter=mukhtalid`}><FontAwesomeIcon icon={faHeadSideVirus} /> মুখতালিত</Link>
        </div>


        </div>
    
        
        
        <div className="flex gap-5 jarahtadil">

        <Link  to={`?filter=muaddal`}>
        <div className="flex items-center gap-3 group ismuaddal">
               <div className={`circle group-hover:border-[#00000029] group-hover:border-[5px] ${filter === 'muaddal' ? 'border-[#00000029] border-[5px]': ''}  h-[20px] w-[20px] bg-[#23ffda] rounded-full `}></div>

                <p className=' text-[#303030] text-[18px]  '>মুআদ্দাল</p> 
        </div></Link>
        <Link  to={`?filter=majrooh`}>
        <div className="flex items-center gap-3 group ismudallis">
               <div className={`circle ${filter === 'majrooh' ? 'border-[#00000029] border-[5px]': ''} group-hover:border-[#00000029] group-hover:border-[5px]  h-[20px] w-[20px]  bg-[#ff3c1a] rounded-full `}></div>
                <p className=' text-[#303030] text-[18px]  '>মাজরুহ</p> 
        </div></Link>
        <Link  to={`?filter=majhool`}>
        <div className="flex items-center gap-3 group ismudallis">
               <div className={`circle ${filter === 'majhool' ? 'border-[#00000029] border-[5px]': ''} group-hover:border-[#00000029] group-hover:border-[5px]  h-[20px] w-[20px]  bg-[#ffd415] rounded-full `}></div>
                <p className=' text-[#303030] text-[18px]  '>মাজহুল</p> 
        </div></Link>
               
               
          
        
        
        </div>

      </div>


      <div className="mt-5 searchitem">
      <Searchitem
      placeholder={"রাবীর নাম লিখুন..."}
      handelsearch={(e) => settSearch(e.target.value)}
      searchkey={searchkey}
      
      />

{/* <Link to={`?search=${searchkey}`}> search</Link> */}
      </div>
     
{ rajuls && rajuls.map((item,index)=>
    <div className={`rajulCard mb-5  shadow-[2px_2px_5px_0px_#ededed] border-b border-l-8 p-3 text-[#565656] ${item.isRajul_type.isMuaddal &&'border-l-[#13ffeb]' || item.isRajul_type.isMajrooh &&'border-l-[#ff320e]'|| item.isRajul_type.isMajhool &&'border-l-[#d9ff00]'}`}>
      <div className="flex justify-between top">
      <p className="id_no w-fit font-[kalpurushansi] text-2xl md:text-3xl ">#{item.id <10 && 0}{item.id}</p>
        <div className="flex gap-4 tags">
          {item.isStarred && <div className="star"><FontAwesomeIcon
            className="text-[#727272] text-[1rem] "
            icon={faStar}
          /></div>}
          {item.isSahabi && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">সাহাবী</p>}
          {item.isRajul_type.isMuaddal && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মুআদ্দাল</p>}
          {item.isRajul_type.isMajrooh && <p className="bg-[#ff320e] text-white p-[2px_23px] h-fit">মাজরুহ</p>}
          {item.isRajul_type.isMajhool && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাজহুল</p>}
          {item.isRajul_type.isMixed && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মিশ্রিত</p>}
        

        </div>
      </div>
      <div className="flex flex-col items-end f_namesand_birth_death ">
        <Link to={`/narrators/${item.id}`}> <p  className="font-[arabickufi] hover:underline text-lg md:text-2xl mb-3" >{item.f_name.arabic}</p></Link>
       
        <p className="text-xl md:text-4xl">{item.f_name.bangla}</p>
        <p className="font-[kalpurushansi] bg-[#767676] text-white px-3 py-[1px] my-1 rounded-[50px]">{item.bd_date.birth} - {item.bd_date.death} wn: </p>          
      </div>
      <div className="fullname">
        <p className=" text-[18px] md:text-2xl text-right border-b pb-[2px] mb-1 border-[#c1c1c1]">ٳسم: {item.name.arabic}</p>
        <p className=" md:text-2xl border-b border-[#c1c1c1] pb-[2px]">নাম: {item.name.bangla}</p>
      </div>
      <div className="mt-3 btns">
      <div className="edit">
        <Link className="bg-[#29cd9f] p-[6px_23px] text-white rounded-sm text-[1.1rem] font-[monospace] font-bold" to={`edit/${item.id}`}>Edit Rajul</Link>

        </div>
       
      </div>

    </div>
    )}
     {resultPerPage < count && (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={count}
              onChange={setCurrentPageNo}
              prevPageText="Prev"
              firstPageText="1st"
              lastPageText="Last"
              nextPageText="Next"
              itemClass="page_item"
              linkClass="page_link"
              activeClass="pageItemActive"
              activeLinkClass="pageLinkActive"
            />
          )}
     </div>
     
     
           
           
    

    
  </div> }
    
    

      
    </>
  );
};

export default Rijal;
