import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./Accordion.css";

function Accordion(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    console.log("Height for ", props.title, ": ", height);
  }, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }
  const func = ()=>{
    toggleAccordion()

    active &&
      setTimeout(function(){

        props.fun(1);	 
       }, 450);
    
    
    
  }

  return (
    <div className="flex flex-col mb-3 accordion__section">
      <div
        className={`accordion  justify-between bg-theme w-full gap-2 flex p-[10px_18px] text-[1.2rem] md:text-[1.5rem] rounded-[7px_7px_0px_0px] text-white md:hover:bg-[#30424e] transition-all duration-300 ease-in-out ${active ? "bg-[#30424e]" : "bg-theme"}`}
        
        onClick={func}
      >
        <p className="accordion__title">{props.title}</p>
        <span style={{ marginLeft: "20px" }}>{active ? <FontAwesomeIcon icon="fa-solid fa-caret-up" /> : <FontAwesomeIcon icon="fa-solid fa-caret-down" />}</span>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          // dangerouslySetInnerHTML={{ __html: props.content }}
        />
        {props.content}
      </div>
    </div>
  );
}

export default Accordion;
