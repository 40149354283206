import React, { useState, useContext } from "react";
import Context from "../../Context";
import Inputfield from "./Inputfield";
import Inputtitle from "./Inputtitle";
import Textarea from "./Textarea";

const Texttahqeeq = () => {
  // const [textArray, setTextArray] = useState([
  //   { arabic: { sanad: "", matan: "" }, bangla: "", takhrij: [] },
  // ]);
   const {textArray,setTextArray} = useContext(Context)

  console.log({textArray})

  const handleAddFields = () => {
    const newFields = {
      arabic: { sanad: "", matan: "" },
      bangla: "",
      takhrij: [],
    };
    setTextArray([...textArray, newFields]);
  };

  const handleRemoveFields = (indexToRemove) => {
    const arrayCopy = [...textArray];
    arrayCopy.splice(indexToRemove, 1);
    setTextArray(arrayCopy);
  };

  const handleAddtakhrij = (index) => {
    const newFields = [...textArray];
    newFields[index].takhrij.push("");
    setTextArray(newFields);
  };

  const handleRemovetakhrij = (index, takhrijIndexToRemove) => {
    const newFields = [...textArray];
    newFields[index].takhrij.splice(takhrijIndexToRemove, 1);
    setTextArray(newFields);
  };

  return (
    <div className="mb-5 single_input Famous nameInput">
        <Inputtitle title={"বর্ণনাটির ইবারত"} />
      {textArray.map((fields, index) => (
        
        <div className="flex flex-col gap-4" key={index}>
          <textarea
            type="text"
            className={`h-[45px]  w-full text-[20px] pl-[15px]  shadow-[0px_1px_2px_0px_#dfdfdf]  outline-none`}
            placeholder={"সনদ আরবী"}
            value={fields.arabic.sanad}
            onChange={(e) => {
              const newFields = [...textArray];
              newFields[index].arabic.sanad = e.target.value;
              setTextArray(newFields);
            }}
          />
          <textarea
            type="text"
            placeholder={"মতন আরবী"}
            className={`h-[45px]  w-full text-[20px] pl-[15px]  shadow-[0px_1px_2px_0px_#dfdfdf]  outline-none`}
            value={fields.arabic.matan}
            onChange={(e) => {
              const newFields = [...textArray];
              newFields[index].arabic.matan = e.target.value;
              setTextArray(newFields);
            }}
          />
          <textarea
            type="text"
            placeholder={"মতন বাংলা"}
            className={`h-[45px]  w-full text-[20px] pl-[15px]  shadow-[0px_1px_2px_0px_#dfdfdf]  outline-none`}
            value={fields.bangla}
            onChange={(e) => {
              const newFields = [...textArray];
              newFields[index].bangla = e.target.value;
              setTextArray(newFields);
            }}
          />
          <div>
            {fields.takhrij.map((takhrijItem, takhrijIndex) => (
              <div key={takhrijIndex}>
                <input

                  type="text"
                  className={`h-[45px]  w-full text-[20px] pl-[15px] shadow-[0px_1px_2px_0px_#dfdfdf]  outline-none`}
                  value={takhrijItem}
                  
                  onChange={(e) => {
                    const newFields = [...textArray];
                    newFields[index].takhrij[takhrijIndex] = e.target.value;
                    setTextArray(newFields);
                  }}
                />
                <button
                  type="button"
                  className="bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
                  onClick={() => handleRemovetakhrij(index, takhrijIndex)}
                >
                  Remove takhrij
                </button>
              </div>
            ))}
            <button type="button" className="bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]" onClick={() => handleAddtakhrij(index)}>
              Add takhrij
            </button>
          </div>
          <button type="button" className="bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]" onClick={() => handleRemoveFields(index)}>
            Remove Fields
          </button>
        </div>
      ))}
      <button type="button" className="bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]" onClick={handleAddFields}>
        Add Fields
      </button>
    </div>
  );
};

export default Texttahqeeq;
