import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api/baseapi";
import Modal from "../../../components/Modal";

function Tahqeeqat() {
  const [tahqeeqat, settahqeeq] = useState([]);
  const [loader, setloader] = useState(true);
  const { tahqeeqs } = tahqeeqat;
  const [modalOpen,setModalopen] = useState(false);

  useEffect(() => {
    api.get("/tahqeeqs").then((res) => settahqeeq(res.data));
    setloader(false);
  }, []);
  console.log("rijals", tahqeeqs);

  const item = {
    id: 4,
    title: { arabic: "this is arabic", bangla: "সালাতে বুকের উপর হাত বাঁধা" },
    narrator: "Ali Radiallahu Anhu",
    isRewyat: {
      isMaqbool: true,
      isMardood: false,
    },
    type: {
      isMarfo: true,
      isMawqof: false,
      isMaqto:false,
      isQaul: false,
    },
    category: ['সালাত','হাত বাঁধা'],

  };
  return (
    
  <div>
    <div className={`TahqeeqCard mb-5 max-w-[700px] m-auto shadow-[2px_2px_5px_0px_#ededed]  border-b border-l-8 p-3 text-[#565656] ${item.isRewyat.isMaqbool &&'border-l-[#13ffeb]' || item.isRewyat.isMardood &&'border-l-[#ff320e]'}`}>
      {modalOpen && <Modal />}
      <div className="flex justify-between top">
      <p className="id_no w-fit font-[kalpurushansi] text-2xl md:text-3xl ">#{item.id <10 && 0}{item.id}</p>
        <div className="flex gap-4 tags">
          {item.isRewyat.isMaqbool && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাকবুল</p>}
          {item.isRewyat.isMardood && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মারদূদ</p>}
          {item.type.isMarfo && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মারফূ’</p>}
          {item.type.isMawqof && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাউকুফ</p>}
          {item.type.isMaqto && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাকতু</p>}
          {item.type.isQaul && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">ক্বওল</p>}
        

        </div>
      </div>

      <div className="title">
        <p className=" md:text-2xl">শিরোনাম: {item.title.bangla}</p>
        {/* <div className="category flex">{item.category.map((i)=><p> > {i}  </p>)}</div> */}

      </div>
      <div className="btns mt-3">
        <div className="edit">
        <Link className="bg-[#29cd9f] p-[6px_23px] text-white rounded-sm text-[1.1rem] font-[monospace] font-bold" to={`/tahqeeqedit/${item._id}`}>Edit Tahqeeq</Link>

        </div>
      </div>

    </div>
    { tahqeeqs && tahqeeqs.map((item,index)=> 
    <div className={`TahqeeqCard mb-5 max-w-[700px] m-auto shadow-[2px_2px_5px_0px_#ededed] border-b border-l-8 p-3 text-[#565656] ${item.isRewyat.isMaqbool &&'border-l-[#13ffeb]' || item.isRewyat.isMardood &&'border-l-[#ff320e]'}`}>
      <div className="flex justify-between top">
      <p className="id_no w-fit font-[kalpurushansi] text-2xl md:text-3xl ">#{item.id <10 && 0}{item.id}</p>
        <div className="flex gap-4 tags">
          {item.isRewyat.isMaqbool && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাকবুল</p>}
          {item.isRewyat.isMardood && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মারদূদ</p>}
          {item.type.isMarfo && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মারফূ’</p>}
          {item.type.isMawqof && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাউকুফ</p>}
          {item.type.isMaqto && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">মাকতু</p>}
          {item.type.isQaul && <p className="bg-[#36dfd7] text-white p-[2px_23px] h-fit">ক্বওল</p>}
        

        </div>
      </div>

      <div className="title">
        <p className=" md:text-2xl">শিরোনাম: {item.title.bangla}</p>
        {/* <div className="category flex">{item.category.map((i)=><p> > {i}  </p>)}</div> */}

      </div>
      <div className="btns mt-3 flex">
        <div className="edit">
        <Link className="bg-[#29cd9f] p-[6px_23px] text-white rounded-sm text-[1.1rem] font-[monospace] font-bold" to={`edit/${item.id}`}>Edit Tahqeeq</Link>

        </div>
        <div className="edit">
        {/* <button className="bg-[#ff2929] p-[6px_23px] text-white rounded-sm text-[1.1rem] font-[monospace] font-bold" onClick={()=> setModalopen(true)}>Delete Tahqeeq</button> */}
        <button className="bg-[#ff2929] p-[6px_23px] text-white rounded-sm text-[1.1rem] font-[monospace] font-bold" onClick={()=> api.delete(`/tahqeeq/${item._id} `)}>Delete Tahqeeq</button>

        </div>
      </div>

    </div>)}
    
  </div>);
}

export default Tahqeeqat;
