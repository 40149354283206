
import React, { useContext, useEffect, useState } from "react";
import Inputfield from "../../components/Inputfield";
import Inputtitle from "../../components/Inputtitle";
import api from "../../../api/baseapi";
import {  useForm } from "react-hook-form";
import {
  addstringtoarray,
  handleAddClick,
  handleRemoveClick,
  hookAddinput,
} from "../../hooks/hookAddinput";
import { useParams } from "react-router-dom";
import Textarea from "../../components/Textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Context from "../../../Context";
import Texttahqeeq from "../../components/Texttahqeeq";

const EditTahqeeq = () => {
  // const [tahqeeq, settahqeeq] = useState({})
  const [tahqeeq, setid] = useState(null);
  const {textArray,setTextArray} = useContext(Context)

  const title = {
    arabic: "وضع يدين تحت السرة امن أخلاق النبوة الثالث",
    bangla: "নাভীর নিচে হাত বাঁধা নবুয়তের তিনটি আখলাকের মধ্যে একটি"
    }
   const text = {
      arabic: {
      sanad: "خبرنا أَبُو الْحُسَيْنِ بْنُ الْفَضْلِ بِبَغْدَادَ، أنا أَبُو عَمْرٍو ابْنُ السَّمَّاكِ، ثنا مُحَمَّدُ بْنُ عُبَيْدِ اللَّهِ بْنِ الْمُنَادِي، ثنا أَبُو حُذَيْفَةَ، ثنا سَعِيدُ بْنُ زَرْبِيٍّ، عَنْ ثَابِتٍ، عَنْ أَنسٍ,\n",
      matan: "مِنْ أَخْلَاقِ النُّبُوَّةِ تَعْجِيلُ الْإِفْطَارِ وَتَأْخِيرُ السُّحُورِ، وَوَضْعُكَ (٢) يَمِينَكَ عَلَى شِمَالِكَ فِي الصَّلَاةِ تَحْتَ السُّرَّةِ"
      },
      bangla: "নবুয়তের আখলাকের মধ্যে রয়েছে দ্রুত ইফতার করা, দেরিতে সাহারি করা, সালাতে নাভীর নিচে ডান হাতকে বাম হাতের উপর রাখা।"
      }
      const [type, setType] = useState({isMarfo:false,isMawqof:false,isMaqtu:false,isQaul:false})
      const [takhrijstring, settakhrijstring] = useState([]);
      const [takhrij, setTakhrij] = useState(['farhan','shakil'])
      const [categorylist,setCategoryList] = useState([])
      const [category, setCategory] = useState([]);
      const [gradeType,setGradeType] = useState({
        isSahih:false,
            isHasan:false,
            isDaif:false,
            isMauzu:false,
      })
      const [isRewyat, setIsRewyat] = useState({ isMaqbool: false, isMardood: false });
      const [who_accepted, setWho_accepted] = useState([
        {
          name: "sdfsdf",
          deathYear: 55,
          quote: { arabic: "sefsef", bangla: "sefsef" },
          ref: "sefsef",
          grade: "sefsef",
        },
      ]);
      const [who_doesnt_accepted, setWho_doesnt_accepted] = useState([
        {
          name: "sdfsdf",
          deathYear: 55,
          quote: { arabic: "sefsef", bangla: "sefsef" },
          ref: "sefsef",
          grade: "sefsef",
        },
      ]);
      const [problems,setProblems] = useState([{
        problem:'',
        ref:'',
        link:''
    }])

  
  const {id:ldd} = useParams()
  // const {istahqeeq_type} = id

  // const [idt, setidt] = useState(id)
  const [lidd, setLidd] = useState(0)
  useEffect(() => {
    api.get('/tahqeeqs').then((res) => setLidd(res.data.tahqeeqCount));
    api.get("/categorys").then((res) => setCategoryList(res.data.categorys));
    

  }, [])

  useEffect(() => {
    api.get(`/tahqeeq/${ldd}`).then((res) => {
      setid(res.data.tahqeeq);
      setTakhrij(res.data.tahqeeq.takhrij)
      setGradeType(res.data.tahqeeq.gradeType)
      setIsRewyat(res.data.tahqeeq.isRewyat)
      setProblems(res.data.tahqeeq.problems)
      setType(res.data.tahqeeq.type)
      setTextArray(res.data.tahqeeq.text)
      setWho_accepted(res.data.tahqeeq.who_accepted)
      setWho_doesnt_accepted(res.data.tahqeeq.who_doesnt_accepted)
      
    });
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(tahqeeq);
  }, [tahqeeq]);

  console.log("rijals", tahqeeq);
  const addbtn = (i) => {
    const check = category.every(item => {
        return item !== i
    })
    if (check) {
        category.push(i)
    }


    setCategory([...category])
    // console.log(displayarray)
    console.log("category data",category)

}
  const dltcateg = (idd)=>{
    if(category.includes(idd)){
        const ar = category.indexOf(idd);
        category.splice(ar,1);
        setCategory([...category])
  
    }
  }
  const handlecat = (categories)=>{
    let myCat =[];
    for(let category of categories){
      myCat.push(
        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />

        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />
        <div>
          
    <div className='bg-[#e8ff1b] flex'>
        
          {category.parentId ? 
          <div className="flex items-center justify-between w-full p-[2px_16px] text-white border-b bg-[#bfbfbf]">
            <p className='cursor-pointer accordion-title whitespace-nowrap' onClick={() => addbtn({_id:category._id,name:category.name, slug:category.slug})} >{category.name}</p>
            </div>
            :null 
            }
        

        <div>
            <div className="accordion-body">
              { category.children.length > 0 && <div>
                  
                      { <p className=" ulul" >{handlecat(category.children)}</p>}
                    {/* {category.children.length > 0 ?(<ul>{handlecat(category.children):null} */}
                

                    {/* <AccordionBody>
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet.
                        </div>
                    </AccordionBody> */}
                </div>}
                
            </div>
        </div>
    </div>
</div>
        // <li key={category.name}>
        //   {category.name}
        //   {category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null}
        // </li>
    
      )
    }
    return myCat;
  }
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      iceCreamType: true,
    },
  });

  const [id,SetIdd] = useState(0)
  const [name, setname] = useState({ arabic: "old name", bangla: "old name" });
  const [f_name, setf_name] = useState({ arabic: "sefsef", bangla: "sefesf" });
  const [q_name, setq_name] = useState("sefsef");
  const [bd_date, setbd_date] = useState({ birth: 196, death: 256 });
  const [isStarred, setisStarred] = useState(false);
  const [tabqa, settabqa] = useState(0);
  const [isSahabi, setisSahabi] = useState(false);
  const [istahqeeq_type, setistahqeeqType] = useState({
    isMuaddal: true,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  });

  const [grade, setgrade] = useState("sdfsdf");
  const [isMudallis, setisMudallis] = useState(true);
  const [isMukhtalit, setisMukhtalit] = useState(true);
  const [isFisSittah, setisFissittah] = useState({
    bukhari: false,
    muslim: false,
    tirmizi: false,
    abuDawud: false,
    nasaee: false,
    ibnMajah: false,
  });
  const [books, setbooks] = useState([{ name: "sdfsdf", link: "sefsef" },{ name: "newnew", link: "sefsef" },{ name: "goodbook", link: "sefsef" }]);
  const [teachers, setteachers] = useState(["sefsefsfe", "sefsef"]);
  const [students, setstudents] = useState(["sefsefsfe", "sefsef"]);
  const [tadeellist, setTadeellist] = useState([
    {
      name: "sdfsdf",
      deathYear: 55,
      quote: { arabic: "sefsef", bangla: "sefsef" },
      ref: "sefsef",
      grade: "sefsef",
    },
  ]);
  const [jarahlist, setJarahlist] = useState([
    {
      name: "sdfsdf",
      deathYear: 55,
      quote: { arabic: "sefsef", bangla: "sefsef" },
      ref: "sefsef",
      grade: "sefsef",
    },
  ]);
  const [description, setdescription] = useState("sdfsfs");
  const [special_desc, setspecial_desc] = useState("sdfsdf");
  const [d_link, setd_link] = useState("sdfsdf");

  // Setting Strings for input
  const [teacherstring, setteacherstring] = useState([]);
  const [studentstring, setstudentstring] = useState([]);

  const handleChangee = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const newfun = (state, setState, event, index, x) => {
    const { name, value, id } = event.target;
    // const new = id
    // const far = id
    const list = [...state];
    // list[index][name][id] = value;
    //  const item = {...x, ["quote"[event.target.name]] : event.target.value, }
    const item = {
      ...x,
      quote: { ...x.quote, [event.target.name]: event.target.value },
    };

    list[index] = item;
    setState(list);
  };
  // setJarahlist({
  //   ...jarahlist,
  //   quote: {
  //     ...jarahlist.quote,
  //     bangla: e.target.value,
  //   },
  // })

  // console.log("tahqeeq type new", isStarred);
  // console.log("user new", user);
  // console.log("jarah new", jarahlist);
  // console.log("tadeel new", tadeellist);
  // console.log('this is stricng', string)
  const addAndClearfield = (string, setstring, state, setstate) => {
    addstringtoarray(string, state, setstate);
    setstring("");
  };
  const handleChange = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const objOfBtns = (e, state, setstate, val) => {
    e.preventDefault();
    setstate({
      ...state,
      [e.target.name]: val,
    });
  };

  const tahqeeqdata = {
    
 
    isRewyat,
    category,
    gradeType,
    type,
    takhrij,
    who_accepted,
    who_doesnt_accepted,
    problems,
    text:textArray

  };
  console.log('newnew', tahqeeq && tahqeeq._id)

  const addtahqeeq = () => {
    api.put(`/tahqeeq/${ tahqeeq && tahqeeq._id}`, tahqeeqdata);
    console.log("alldata new", tahqeeqdata);
  };

  const newsubmit = (data) => {

   const {title,text, grade,id,narrator} = data
    // e.preventDefault();
    // ...data,
    // const neww = { ...data, ...tahqeeqdata };
    const neww = { title,text, grade,id,narrator, ...tahqeeqdata };
    api.put(`/tahqeeq/${tahqeeq && tahqeeq._id}`, neww,{withCredentials:true});
    // console.log(tahqeeqdata, 'hello new')
    // console.log('data', data )
    // console.log('errors', errors)
    // console.log('testname', name)
    console.log("testname", neww);
  };
  const onChange = (event) => {
    console.log("change", !event.target.value);
  };
  const rainbow = ["array", "new"];

  return (
    <form className="" onSubmit={handleSubmit(newsubmit)}>
      
      <div className="container px-10 m-auto">
        <div className="h-[80px]">
        <div className="right-0 flex justify-end w-full h-12 mb-9">
          <button type="submit" className="bg-white hover:border-2 hover:border-[#fbff29] py-0 px-[32px] rounded-[50px] text-xl font-semibold text-[#4a4a4a] shadow-[-1px_1px_3px_0px_#d9d9d9]">
            সাবমিট করুন
          </button>

          {/* <input type="submit" className="bg-slate-600" value="সাবমিট করুন" /> */}
        </div>
      </div>

      
        {/* <p>{id && rajul.id - 1}</p> */}
        {/* <input
                    
                    value={id}
                    onChange={(e)=>setid(e.target.value)}
                    placeholder="Id"
                  /> */}
        {/* <input name="firstName" type="text" {...register('firstName')} className="form-control" /> */}
        <div className="id ">
        <Inputtitle title={"আইডি নং "} />

        <div className="flex flex-col items-center gap-4 mb-5 md:flex-row">

          {/* <p className="w-[2] bg-[#d8e7e6] py-[2px] px-[17px] text-[#545454] font-bold rounded-[4px] shadow-[1px_1px_3px_-1px_#ababab] ">পূর্বের আইডি নং: 0{lidd}</p> */}

<div className="w-[200px]">
        <Inputfield
          placeholder={"id"}
          name="id"
          v_hook={register("id", {
            required: true,
            // value:lidd + 1,
            valueAsNumber: true,
            pattern:{
              value: /^(0|[1-9]\d*)(\.\d+)?$/
           },
          })}
          newstyle={
            errors.id && errors.id ? "border-[red] border-b-[3px]" : null
          }
        /></div>
        </div>
        </div>
        

        <div className="mb-5 single_input nameInput">
          <Inputtitle title={"শিরোনাম"} />
          <div className="flex flex-col gap-4 md:flex-row">
            <Inputfield
              placeholder={"আরবী"}
              name="arabic"
              v_hook={register("title.arabic", {
                required: true,
                value: title.arabic || "",
              })}
              newstyle={
                errors.name && errors.title.arabic
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />

            <Inputfield
              placeholder={"বাংলা"}
              name="bangla"
              v_hook={register("title.bangla", {
                required: true,
                value: title.bangla || "",
              })}
              newstyle={
                errors.name && errors.title.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />
          </div>
        </div>
        <Texttahqeeq />

        {/* <div className="mb-5 single_input Famous nameInput">
          <Inputtitle title={"বর্ণনাটির ইবারত"} />
          <div className="flex flex-col gap-4">
            <Textarea
              placeholder={"সনদ আরবী"}
              name="arabic"
              rows={5}
              v_hook={register("text.arabic.sanad", {
                required: true,
                value: text.arabic.sanad || "",
              })}
              newstyle={errors.arabic ? "border-[red] border-b-[3px]" : null}
            />
            <Textarea
              placeholder={"মতন আরবী"}
              name="bangla"
              v_hook={register("text.arabic.matan", {
                required: true,
                // value: f_name.bangla || "",
              })}
              newstyle={
                errors.f_name && errors.f_name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
              // fun={(e) => handleChange(e, f_name, setf_name)}
            />
            <Textarea
              placeholder={"মতন বাংলা"}
              name="bangla"
              v_hook={register("text.bangla", {
                required: true,
                // value: f_name.bangla || "",
              })}
              newstyle={
                errors.f_name && errors.f_name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
              // fun={(e) => handleChange(e, f_name, setf_name)}
            />
          </div>
        </div> */}

        <div className="mb-5 single_input">
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="takhrij">
              <Inputtitle title={"তাখরীজ"} />
              <div className="div students">
            <Inputfield
              placeholder={"বই এর নাম"}
              fun={(e) => settakhrijstring(e.target.value)}
              value={takhrijstring}
            />

            <button
            type="button"
            className=" bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
              onClick={() =>
                takhrijstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      takhrijstring,
                      settakhrijstring,
                      takhrij,
                      setTakhrij
                    )
              }
            >
              add input
            </button>

            {/* {takhrij.map((x, i) => {
              return (
                <div key={i} className="my-3 box first-letter">
                  <p>{x}</p>
                  <div className="flex flex-col md:flex-row "></div>

                  <div className="btn-box">
                    {takhrij.length !== 0 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(takhrij, setTakhrij, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })} */}
          </div>

            </div>

            <div className="narrator">
              <Inputtitle title={"বর্ণনাকারী"} />
              <div className="flex">
                <Inputfield
                  placeholder={"বর্ণনাকারীর নাম"}
                  name="narrator"
                  v_hook={register("narrator", {
                    // value:q_name || ""
                  })}
                  newstyle={
                    errors.narrator && errors.narrator
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
              </div>
            </div>
            <div className="categorry flex-[1]">
              <Inputtitle title={"ক্যাটেগরি"} />
              {/* <div className="flex">
                <Inputfield
                  placeholder={"ক্যাটেগরি"}
                  name="narrator"
                  v_hook={register("narrator", {
                    // value:q_name || ""
                  })}
                  newstyle={
                    errors.narrator && errors.narrator
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
                
              </div> */}
              <div className="category shadow-[0px_1px_6px_0px_#ededed] h-fit">
      
      { categorylist.length > 0 && <p className="flex cat">{handlecat(categorylist)}</p> }
      <div className='flex flex-wrap gap-3 justify-evenly bg-[#ffffff] rounded-[0px_0px_5px_5px] p-[0px_10px]'>
            
            {category.map((i) => {
                return <p onClick={()=>dltcateg(i)} ><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> {i.name}</p>
                })}
        </div>

      </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 hukums md:flex md:flex-row">
          <div>
            <Inputtitle title={"গ্রহণযোগ্যতা"} />
            <div className="flex">
              {gradeType &&
                Object.entries(gradeType).map(([key, val], i) => (
                  <button
                  type="button"
                    className={`${
                      val ? "bg-[#a9f7ff]" : "bg-slate-400"
                    } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                    name={key}
                    onClick={(e) =>
                      objOfBtns(e, gradeType, setGradeType, !val)
                    }
                    key={i}
                  >
                    {key === "isSahih" && <>সহীহ</>}
                    {key === "isHasan" && <>হাসান</>}
                    {key === "isDaif" && <>যঈফ</>}
                    {key === "isMauzu" && <>বানোয়াট</>}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <Inputtitle title={"বর্ণনার ধরন"} />
            <div className="flex flex-wrap">
              {Object.entries(type).map(([key, val], i) => (
                <button
                type="button"
                  className={`${
                    val ? "bg-[#a9f7ff]" : "bg-slate-400"
                  } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                  name={key}
                  onClick={(e) =>
                    objOfBtns(e, type, setType, !val)
                  }
                  key={i}
                >
                  {key === "isMarfo" && <>মারফূ</>}
                  {key === "isMawqof" && <>মাওকূফ</>}
                  {key === "isMaqtu" && <>মাকতূ</>}
                  {key === "isQaul" && <>ক্বওল</>}

                </button>
              ))}
            </div>
          </div>
          <div>
            <Inputtitle title={"বর্ণনার ধরন"} />
            <div className="flex flex-wrap">
              {Object.entries(isRewyat).map(([key, val], i) => (
                <button
                type="button"
                  className={`${
                    val ? "bg-[#a9f7ff]" : "bg-slate-400"
                  } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                  name={key}
                  onClick={(e) =>
                    objOfBtns(e, isRewyat, setIsRewyat, !val)
                  }
                  key={i}
                >
                  {key === "isMaqbool" && <>মাকবুল</>}
                  {key === "isMardood" && <>মারদূদ</>}


                </button>
              ))}
            </div>
          </div>
  
          
        </div>

        <div className="mb-5 string hukum">
          <div className="hukum">
            <Inputtitle title={"হুকুম"} />
            {/* <Inputfield
              placeholder={"হুকুম"}
              fun={(e) => setgrade(e.target.value)}
            /> */}
            <Inputfield
                  placeholder={"হুকুম"}
                  name="grade"
                  v_hook={register("grade", {
                    
                  })}
                  newstyle={
                    errors.grade && errors.grade
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
          </div>
        </div>

        <div className="mb-5 bookscontainer">
          <div className="div">
          <Inputtitle title={"বই এর তালিকা:"} />
            {books.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(books, setbooks, e, i)}
                    />

                    <Inputfield
                      placeholder={"লিংক"}
                      value={x.link}
                      name="link"
                      fun={(e) => hookAddinput(books, setbooks, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {books.length !== 1 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() => handleRemoveClick(books, setbooks, i)}
                      >
                        Remove
                      </button>
                    )}
                    {books.length - 1 === i && (
                      <button
                      type="button"
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(books, setbooks, {
                            name: "",
                            link: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex mb-5 teacherstudentscontainer">
          <div className="div teachers">
            <Inputtitle title={"উস্তাযের তালিকা"} />
            <Inputfield
              placeholder={"উস্তাযের নাম"}
              fun={(e) => setteacherstring(e.target.value)}
              value={teacherstring}
            />

            <button
            className=" bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
            type="button"
              onClick={() =>
                teacherstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      teacherstring,
                      setteacherstring,
                      teachers,
                      setteachers
                    )
              }
            >
              add input
            </button>

            {teachers.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <p>{x}</p>
                  {/* <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    // fun={(e) => addstringtoarray(x,teachers, setteachers)}
                  />

                </div> */}

                  <div className="my-3 btn-box">
                    {teachers.length !== 0 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(teachers, setteachers, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="div students">
            <Inputtitle title={"ছাত্র তালিকা"} />
            <Inputfield
              placeholder={"ছাত্রের নাম"}
              fun={(e) => setstudentstring(e.target.value)}
              value={studentstring}
            />

            <button
            type="button"
            className=" bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
              onClick={() =>
                studentstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      studentstring,
                      setstudentstring,
                      students,
                      setstudents
                    )
              }
            >
              add input
            </button>

            {students.map((x, i) => {
              return (
                <div key={i} className="my-3 box first-letter">
                  <p>{x}</p>
                  <div className="flex flex-col md:flex-row "></div>

                  <div className="btn-box">
                    {students.length !== 0 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(students, setstudents, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"যারা গ্রহনযোগ্য আখ্যা দিয়েছেন:"} />

            {who_accepted.map((x, i) => {
              return (
                <div key={i} className="box first-letter ">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                      // fun={(e) => {newfun(tadeellist, setWho_accepted, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                      // fun={(e) => {newfun(tadeellist, setWho_accepted, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(tadeellist, setWho_accepted, e, i)}
                      fun={(e) => {
                        newfun(who_accepted, setWho_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(tadeellist, setWho_accepted, e, i)}
                      fun={(e) => {
                        newfun(who_accepted, setWho_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(tadeellist, setWho_accepted, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(who_accepted, setWho_accepted, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {who_accepted.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(who_accepted, setWho_accepted, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {who_accepted.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(who_accepted, setWho_accepted, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"যে ইমামগণ জারাহ করেছেন"} />
            {who_doesnt_accepted.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(who_doesnt_accepted, setWho_doesnt_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(who_doesnt_accepted, setWho_doesnt_accepted, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(who_doesnt_accepted, setWho_doesnt_accepted, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {who_doesnt_accepted.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(who_doesnt_accepted, setWho_doesnt_accepted, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {who_doesnt_accepted.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(who_doesnt_accepted, setWho_doesnt_accepted, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"সনদের সমস্যাগুলো"} />
            {problems.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"সমস্যা"}
                      name="problem"
                      value={x.problem}
                      fun={(e) => hookAddinput(problems, setProblems, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      name="ref"
                      value={x.ref}
                      fun={(e) => hookAddinput(problems, setProblems, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"লিংক"}
                      value={x.link}
                      name="link"
                      fun={(e) => hookAddinput(problems, setProblems, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {problems.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(problems, setProblems, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {problems.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(problems, setProblems, {
                            problem:'',
                            ref:'',
                            link:''
                        })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        


        <div className="mb-5 download link">
          <Inputtitle title={"ডাউনলোড লিংক"} />
          <Inputfield placeholder={"ডাউনলোড লিংক"} name="d_link"
              v_hook={register("d_link", {
               
                // value: f_name.bangla || "",
              })} />
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে বিস্তারিত আলোচনা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
     
    "

              name="description"
              {...register("description", {
               
                // value: f_name.bangla || "",
              })}
              
    
    ></textarea>
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে কিছু বিশেষ কথা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
      
    "
    name="description"
    {...register("special_desc", {
     
      // value: f_name.bangla || "",
    })}
    
    ></textarea>
        </div>
      </div>
    </form>
  );
};

export default EditTahqeeq;
