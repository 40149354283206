import React, { useEffect, useState } from 'react'


import axios from 'axios'
// import Pagename from '../../components/Pagename'
import { motion } from 'framer-motion'
import api from '../api/baseapi'
import Pagename from '../components/Pagename'
import { toast } from 'react-toastify'

const Contact = () => {

    const [name, setname] = useState('');
    const [sub, setsub] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState(0);
    const [coment, setcoment] = useState('')
    const [message, setMessage] = useState('')

    const upvid =()=>{


      let datas ={
          name:name,
          sub:sub,
          email:email,
          number:number,
          comment:coment,
      
          
      }
      api.post("/sendmail",datas).then((res)=>toast.success(res.data.message));
      alert('submited')
      console.log('alldata', datas)
  }
  return (
    <>
    <motion.div
  initial={{opacity:0}}
  animate={{opacity:1}}
    >
      <div className='m-[10px_auto] w-[90%] md:w-[85%]'>
      <Pagename name={"যোগাযোগ পাতা"} />
      <div className="container grid grid-cols-1 gap-12 md:grid-cols-3 mt-[50px] ">
      
      
            <div className="inputbody md:col-span-2 ">
            <p className="nukta bg-[#383838] w-fit py-[4px] px-[24px] text-2xl mb-[14px] text-white font-bold rounded-sm" >আপনার প্রশ্ন অথবা বার্তা পাঠান:</p>
            
              <div className="inputfild grid gap-4 grid-cols-1 md:grid-cols-2 bg-white p-8 rounded-2xl shadow-[1px_1px_5px_0px_rgba(1,1,1,0.05)]">
                  <div className="div">
                  <p className='text-lg'>আপনার নাম</p>
                    <input className='bg-[#e6e6e6] p-2 rounded-lg w-full' type="text" onChange={e=>setname(e.target.value)} />
                  </div>
                  <div className="div">
                  <p className='text-lg'>বিষয়</p>
                    <input className='bg-[#e6e6e6] p-2 rounded-lg w-full' type="text" onChange={e=>setsub(e.target.value)} />
                  </div>
                  <div className="div">
                  <p className='text-lg'>ই-মেইল</p>
                    <input className='bg-[#e6e6e6] p-2 rounded-lg w-full' type="text" onChange={e=>setemail(e.target.value)} />
                  </div>
                  <div className="div">
                  {/* <p className='text-lg'>ফোন নাম্বার</p>
                    <input className='bg-[#e6e6e6] p-2 rounded-lg w-full' type="text" onChange={e=>setnumber(e.target.value)}/> */}
                  </div>
                  <div className="div md:col-span-2">
                  <p className='text-lg'>আপনার বার্তা</p>
                    <textarea className='w-full h-[140px] bg-[#e6e6e6] p-2 rounded-lg' type="text" onChange={e=>setcoment(e.target.value)}/>
                  </div> 
                  <button onClick={upvid} className='bg-[#106690] w-[120px] p-[5px_10px] text-center rounded-full text-white mb-3'>সাবমিট</button> 
              </div>     
            </div>
            {/* <div className="client_info">
              {home_data.map(({title, description, imgurl,socialImgandLink})=>{
                return(
                  <div className="imgcard bg-white shadow-[1px_1px_5px_0px_rgba(1,1,1,0.05)] flex gap-2 flex-col items-center rounded-2xl p-8 mb-16">
                     <div className="h_img_wrapper h-[170px] w-[170px] inline-block relative rounded-[50%] ">
                    <img className=" rounded-[50%] w-30px" src={imgurl} alt={title}  />
                  </div>
                  <p className='font-semibold'>{title}</p>
                  <div className="socil_images">
                  {socialImgandLink.map(item =>{
                          return (
                            <a href={item.lastName} target='_blank'>
                              <img src={item.firstName} className="icon h-[30px]" />
                            </a>
                          )
                        })}
                        <SocialLinks />
                  </div>
              </div>
                )
              })}
              
              <div className="email bg-white shadow-[1px_1px_5px_0px_rgba(1,1,1,0.05)] flex flex-col items-start rounded-2xl p-8 mb-16">
                <p>আমাদের সাথে যোগাযোগ করুন</p>
              </div>
            </div> */}
            
          </div> 

      </div>

      
    </motion.div>
      
    </>
  )
}

export default Contact