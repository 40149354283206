import React from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import Container from "./Container";

const Footer = () => {
  return (
    <div className="bg-theme text-white">
      <Container>
        <div className="col1">
          <Link className="flex md:block justify-center" to="/">
            <img className="w-[112px]" src={logo}></img>
          </Link>
           <div className="email">
            ahletahqeeq@gmail.com
           </div>
           
        </div>
      </Container>
    </div>
  );
};

export default Footer;
