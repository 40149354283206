import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Accordion from '../test/components/Accordion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import api from '../api/baseapi'
import Loader from "../components/Loader";
import Tahqeeqtag from "../components/tahqeeq/Tahqeeqtag";
import Cardbtn from "../components/Cardbtn";
// Muhaddith BD - Ja'me As-Sahih
// ৫ নং হাদীছঃ (নববী চরিত্র)
// ====
// ====
// (3901 - حَدَّثَنَا عَفَّانُ، حَدَّثَنَا حَمَّادُ بْنُ سَلَمَةَ، أَخْبَرَنَا عَاصِمٌ ابْنُ بَهْدَلَةَ، عَنْ زِرِّ بْنِ حُبَيْشٍ، عَنْ عَبْدِ اللهِ بْنِ مَسْعُودٍ، قَالَ: كُنَّا يَوْمَ بَدْرٍ كُلُّ ثَلَاثَةٍ عَلَى بَعِيرٍ، كَانَ أَبُو لُبَابَةَ، وَعَلِيُّ بْنُ أَبِي طَالِبٍ، زَمِيلَيْ رَسُولِ اللهِ صَلَّى اللهُ عَلَيْهِ وَسَلَّمَ، قَالَ: وَكَانَتْ عُقْبَةُ رَسُولِ اللهِ صَلَّى اللهُ عَلَيْهِ وَسَلَّمَ، قَالَ: فَقَالَا نَحْنُ نَمْشِي عَنْكَ، فَقَالَ: " مَا أَنْتُمَا بِأَقْوَى مِنِّي، وَلَا أَنَا بِأَغْنَى عَنِ الْأَجْرِ مِنْكُمَا)
// অনুবাদঃ আব্দুল্লাহ্ ইবনে মাস’ঊদ (রাঃ) বলেনঃ আমরা বদরের দিন প্রত্যেক উটে ৩ জন ছিলাম। হযরহ আবূ লুবাবাহ (রাঃ), হযরত আলী (রাঃ) এবং রাসূলুল্লাহ (সাঃ) একটি উটে ছিল। যখন রাসূলুল্লাহ্ (সাঃ) এর পায়ে হাটার পালা আসলো তখন দুজন সাহাবান অনুরোধ করলেনঃ হে! রাসূলুল্লাহ (সাঃ) আপনি উটে যান আমরা হেটে যাই। রাসূলুল্লাহ্ (সাঃ) বললেনঃ না তোমরা আমার চেয়ে বেশি শক্তিশালী আর না আমি তোমাদের থেকে কম প্রতিদানের আশা করি।– মুসনাদে আহমাদ হা/৩৯০১, মুসনাদে আবী ইয়ালা হা/৫৩৩৮, সাহীহ ইবনে হিব্বান হা/৪৭৩৩, শারহুস সুন্নাহ লিল বাগাভী।
// .
// হাদীছের মানঃ হাসান লিযাতিহ। 
// ইমাম হাকেম (রহঃ) বলেনঃ হাদীছটি মুসলিমের শর্তে সহীহ্, কিন্তু শায়খাইন তা নকল করেন নি। ইমাম যাহাবী (রহঃ) তার সমর্থন করেছেন।
// ইমাম আলবানী, শুয়াইব আরনাউত্ব এবং হুসাইস সালিম আসাদ আছিম থাকার কারণে হাসান আখ্যা দিয়েছেন।

const TahqeeqDtls = () => {
  const {id} = useParams()


  useEffect(() => {
    api.get(`/tahqeeq/${id}`).then((res) => {
      // setid(res.data.rajul);
      settitle(res.data.tahqeeq.title)
      setId(res.data.tahqeeq.id)
      // setFname(res.data.tahqeeq.f_name)
      setIsrewyat(res.data.tahqeeq.isRewyat)
      setIsgrade(res.data.tahqeeq.grade)
      setType(res.data.tahqeeq.type)
      setText(res.data.tahqeeq.text)
      setTakhrij(res.data.tahqeeq.takhrij)
      setKeywords(res.data.tahqeeq.keywords)
      setCategory(res.data.tahqeeq.category)
      setNarrator(res.data.tahqeeq.narrator)
      setNarrators(res.data.tahqeeq.narrators)
      setSanads(res.data.tahqeeq.sanads)
      setAslikehadith(res.data.tahqeeq.aslikehadith)
      setWho_accepted(res.data.tahqeeq.who_accepted)
      setWho_doesnt_accepted(res.data.tahqeeq.who_doesnt_accepted)
      setMatan_desc(res.data.tahqeeq.matan_desc)
      setWatch_others(res.data.tahqeeq.watch_others)
      setD_link(res.data.tahqeeq.d_link)
      setSlug(res.data.tahqeeq.slug)
      setHitcount(res.data.tahqeeq.hit_count)
      setProblems(res.data.tahqeeq.problems)
      
    });
  }, []);
  const [title, settitle] = useState('')
  // const [f_name, setFname] = useState({})
  const [isRewyat, setIsrewyat] = useState('')
  const [grade, setIsgrade] = useState('')
  const [type, setType] = useState('')
  const [text, setText] = useState({})
  const [takhrij, setTakhrij] = useState([])
  const [keywords, setKeywords] = useState([])
  const [category, setCategory] = useState([])
  const [narrator, setNarrator] = useState('')
  const [narrators, setNarrators] = useState([])
  const [sanads, setSanads] = useState([])
  const [aslikehadith, setAslikehadith] = useState([])
  const [problems, setProblems] = useState([])
  const [who_accepted, setWho_accepted] = useState([])
  const [who_doesnt_accepted, setWho_doesnt_accepted] = useState([{
    name: "",
    deathYear: 0,
    quote: { arabic: "", bangla: "" },
    ref: "",
    grade: "",
  },])
  const [matan_desc, setMatan_desc] = useState('')
  const [d_link, setD_link] = useState('')
  const [slug, setSlug] = useState('')
  const [watch_others, setWatch_others] = useState([])
  const [idd, setId] = useState(0)
  const [hit_count, setHitcount] = useState(0)






  const f_name = {arabic:'محمد بن اسماعيل البخاري', bangla: 'নববী চরিত্র (উটের পালাতে হেঁটে চলা)'}
  const q_name = 'আবূ আব্দুল্লাহ'
  const bd_date = {birth: 323, death: 344}
  const isStarred = true;
  const tabqah = 0
  const isSahabi = true
  const isRajul_type = {isMuaddal: true, isMajrooh: false, isMajhool:false, isMixed: false}

  const isMudallis = true;
  
  const isMukhtalit = true;
  const isFissittah = {bukhari:true, muslim:true, tirmizi: true, abu_dawud:false, nasai:false, ibn_majah:true};
  const isfemale = false;
  const books = ['সহীহ বুখারী', 'তারীখুল কাবির', 'যুআফা', ' জুয আল কিরআত']
  const teachers = ['Imam Ahmad', 'Imam Ali Ibn Madani', 'Imam Yahiya ibn mayeen', 'Imam Makki bin Ibrahim']
  const students = ['Imam Ahmad', 'Imam Ali Ibn Madani', 'Imam Yahiya ibn mayeen', 'Imam Makki bin Ibrahim']
  const tadeellist = [{name: 'Imam ahmad bin Hanbal', deathYear: 248, quote: {arabic: ' He is good', bangla: 'He is so good'}, ref:'Tarikhe baghdad', grade: 'Sahih'}, {name: 'Imam ahmad bin Hanbal', deathYear: 248, quote: {arabic: ' He is good', bangla: 'He is so good'}, ref:'Tarikhe baghdad', grade: 'Sahih'},{name: 'Imam ahmad bin Hanbal', deathYear: 248, quote: {arabic: ' He is good', bangla: 'He is so good'}, ref:'Tarikhe baghdad', grade: 'Sahih'}]  
  const jarahlist = [{name: 'Imam ahmad bin Hanbal', deathYear: 248, quote: {arabic: ' He is good', bangla: 'He is so good'}, ref:'Tarikhe baghdad', grade: 'Sahih'}, {name: 'Imam ahmad bin Hanbal', deathYear: 248, quote: {arabic: ' He is good', bangla: 'He is so good'}, ref:'Tarikhe baghdad', grade: 'Sahih'},{name: 'Imam ahmad bin Hanbal', deathYear: 248, quote: {arabic: ' He is good', bangla: 'He is so good'}, ref:'Tarikhe baghdad', grade: 'Sahih'}]
  const description = 'He is so good, Imam Faqeeq, Mujtaheed'
  const special_desc = 'He is so good, Imam Faqeeq, Mujtaheed'

  

  
  const [ToggleState, setToggleState] = useState(1);

  const [open, setopen] = useState(false);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

    var wdaSorted = who_doesnt_accepted.sort((a, b) => a.deathYear-b.deathYear);

  return (!title  ?  <Loader />:
    <div className="">
      {/* <div className="h-[80px] w-full"></div> */}

      {/* <div className="h-[80px] bg-slate-500 top-0 fixed w-full"></div> */}
      <div className="p-1  md:p-4 shadow-[1px_4px_5px_2px_#f1f1f1]">
        <div className="flex items-center gap-2 mb-3 id_star">
        
        <div className="toper bg-theme w-full  p-[10px_18px] text-[1.4rem] md:text-[1.7rem] rounded-[7px] text-white">
        <div className="flex gap-2 id ">
            <p className="font-[kalpurushansi]">{id<10 && 0}{id}</p>
            <p className="">{title.bangla}</p>

            </div>
            <div className="flex justify-between topbtm">
            <div className="flex gap-2 mb-1 narrator">
            <p className="takhrij  rounded-sm  w-fit text-[14px] md:text-[16px]   "><FontAwesomeIcon className="text-[white]" icon="fa-solid fa-circle-user" /> {narrator}</p>
          </div>
            <div className="flex gap-3">
            {isRewyat.isMaqbool && <p className="sahabi  bg-[#94ebff] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              {" "}
              মাক্ববুল{" "}
            </p> }
            {/* <>{isRewyat.isMardood &&  <Cardbtn  color={'bg-reddish'} text={"মাজরুহ"} />}</> */}
            <Cardbtn value={isRewyat.isMardood} color={'bg-reddish'} text={"মারদূদ"} />
            <Cardbtn value={type.isMarfo} color={'bg-marfu'} text={"মারফু’"} />
            <Cardbtn value={type.isMawqof} color={'bg-marfu'} text={"মাউকুফ’"} />
            <p className="font-[kalpurushansi] text-[14px] md:text-[16px] p-[0px_5px]"><span className="p-2"><FontAwesomeIcon icon="fa-solid fa-eye" /></span>{hit_count}</p>


            {type.isMaqtu &&
            <p className="sahabi bg-[#11ffbb] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
               <>মাজহুল</>
            </p>}
            {type.isQaul &&
            <p className="sahabi bg-[#11ffbb] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
               <>মিশ্রিত</>
            </p>}
          </div>
            </div>
        
        </div>
          
          
        </div>

        <div
          className={`tabqahandsahabi ${
            tabqah === 0 ? "justify-end" : "justify-between"
          } mb-2  gap-2 flex`}
        >
          {tabqah > 0 && (
            <p className="tabqa bg-[#525252] text-white px-2 py-1">তাবকাহ: ৪</p>
          )}
          
        </div>
       
<div className="namediv border-b border-[#a7a7a7] mb-3 pb-6">
<div className="full_naem ">

  {text.map((item,index)=><div className="text">
  <div className="sanad">
  <Tahqeeqtag text={<>{text.length > 1  && <span className="font-[kalpurushansi]">{index + 1} bs</span>} <span>সনদ</span> </> } />

    <p className="text-right md:text-[1.5rem] font-['uthmani'] leading-10 text-[19px]  my-4 px-6 py-3 border-2 border-[#ebebeb] rounded-lg arabic">
           {/* {text.arabic.matan} */}
           {item.arabic && item.arabic.sanad}

          </p>
  </div>

  <div className="flex flex-col gap-2 matans md:flex-row-reverse">
  <div className="flex-1 matan">
  <Tahqeeqtag text={'মতন'} />
  <p className="text-right md:text-[1.5rem] text-[19px]  my-4 px-6 py-3 border-2  leading-10 font-['uthmani'] border-[#ebebeb] rounded-lg arabic">
           {/* {text.arabic.matan} */}
  
           {item.arabic && item.arabic.matan}

          </p>

  </div>
  <div className="flex-1 bangla">
           <Tahqeeqtag text={'অনুবাদ'} />
            <p className="md:text-xl text-[19px] text-justify  my-4 px-6 py-3 border-2 border-[#ebebeb] rounded-lg" >{item.bangla}</p> {" "}
          </div>

  </div>
  <div className="flex flex-col gap-2 mb-2 takhrij md:flex-row">
  <Tahqeeqtag text={'তাখরীজ'} />

            <div className="flex flex-wrap items-center gap-2">
            {item.takhrij.map((i,x)=> <>
            <p className="takhrij h-fit  rounded-sm  w-fit px-[18px] py-[3px] text-base md:text-[1.2rem]"><FontAwesomeIcon className="text-[#727272]" icon="fa-solid fa-file" /> {i}</p>

            
           
            </>
             ) }

            </div>
             {" "}
          </div>
  </div>)}
  
  

          
          
 


          
          
        </div>

</div>
<div className="flex gap-2 mb-2 narrator">
<Tahqeeqtag text={'হুকুম'} />

            <p className=" rounded-sm w-fit px-[18px] py-[3px] font-bold text-base md:text-[1.2rem] shadow-[2px_2px_0px_0px_#efefef]">{grade}</p>
          </div>


        { wdaSorted[0].name &&  <div>
            <Accordion
              title="যারা অগ্রহণযোগ্য আখ্যা দিয়েছেন:"
              content={
                  <div
                    className="transition-[height_200ms] scroll-smooth"
                  >
                    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>
                          <tr className="hidden md:flex">
                            <th className="flex-[2]">ইমামের নাম:</th>
                            <th className="flex-[0.5]">মৃত্যু সন:</th>
                            <th className="flex-[3]">বক্তব্য:</th>
                          </tr>
                          {wdaSorted.map((i,index)=>  <tr className="flex flex-col align-top md:flex-row">
                            <td className="flex-[2] flex gap-3 text-[19px]"> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            <p className="block whitespace-nowrap md:hidden"><span className="font-['kalpurushansi'] ">{i.deathYear}</span>  হিঃ</p> 
                            </td>

                            <td className="hidden md:block flex-[0.5] "   > <p className="whitespace-nowrap"><span className="font-['kalpurushansi']">{i.deathYear}</span>  হিঃ</p> ‍</td>
                            <td className="tadeel flex-[3] flex flex-col gap-1">
                              <tr className="flex justify-end ">
                               {i.quote.arabic}
                              </tr>
                              <tr className="bangla text-[19px]">
                                {i.quote.bangla}
                              </tr>
                              <tr className="bangla">
                                <p className="ref p-[1px_13px] text-[1.1rem] w-fit font-bold shadow-[0_0_3px_0px_#e1e1e1] bg-[#b5ffd6]">{i.ref}</p>

                               
                              </tr>
                              <tr className="bangla "><p className="grade bg-[#7bffb6] w-fit p-[1px_13px] text-[1rem] text-[#353535]">{i.grade}</p></tr>

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div>
                  </div>

              }
            />
          </div>
          
        }
<div>
  <p className="nukta bg-theme  p-[10px_18px] text-[1.2rem] md:text-[1.5rem] mb-[14px] text-white  rounded-sm">হাদীসটি সম্পর্কে নুকতা:</p>

{problems && problems.map((i,index)=> <div className="flex flex-col gap-1 problem md:flex-row"><span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea] md:text-xl text-[19px]  w-fit">{index+1}</span><p className="md:text-xl text-[19px]">{i.problem}</p> <p><a className="link rounded-sm bg-theme p-[1px_8px] text-white" href={i.link}>{i.ref} <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" /></a></p> </div>)}
</div>
        
      </div>
    </div>
  );
};

export default TahqeeqDtls;
