import React from 'react'
import  Accordions from "../test/components/Accordion";

const Qaulsofimams = ({array, sortedarray,title}) => {
  return (
    <>{ array.length  !== 0 &&  <div>
        <Accordions
          title={title}
          content={
              <div
                className="transition-[height_200ms] scroll-smooth"
              >
                <div className="tadeels">
                  <table id="customers" className="md:text-[1.2rem] text-[1rem]">
                    <tbody>
                      <tr className="hidden md:flex ">
                        <th className="flex-[2]">ইমামের নাম:</th>
                        <th className="flex-[0.5]">মৃত্যু সন:</th>
                        <th className="flex-[3]">বক্তব্য:</th>
                      </tr>
                      {sortedarray.map((i,index)=>  
                       <tr className="flex flex-col align-top md:flex-row border-b border-[#dbdbdb]">
                        <td className="flex-[2] flex gap-3"> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                        <p className="block whitespace-nowrap md:hidden"><span className="font-['kalpurushansi'] ">{i.deathYear}</span>  হিঃ</p> 
                        </td>

                        <td className="hidden md:block flex-[0.5] "   > <p className="whitespace-nowrap"><span className="font-['kalpurushansi']">{i.deathYear}</span>  হিঃ</p> ‍</td>
                        <td className="tadeel flex-[3] flex flex-col gap-1">
                          <tr className="flex justify-end text-right ">
                           {i.quote.arabic}
                          </tr>
                          <tr className="bangla">
                            {i.quote.bangla}
                          </tr>
                          <tr className="bangla">
                            <p className="ref p-[1px_13px] text-[1.1rem] w-fit font-bold shadow-[0_0_3px_0px_#e1e1e1] bg-[#b5ffd6]">{i.ref}</p>

                           
                          </tr>
                          <tr className="bangla "><p className="grade bg-[#7bffb6] w-fit p-[1px_13px] text-[1rem] text-[#353535]">{i.grade}</p></tr>

                        </td>
                      </tr>
                      )}
                     

                    </tbody>
                  </table>
                </div>
              </div>

          }
        />
      </div>
      
    }</>
  )
}

export default Qaulsofimams