import React from "react";

const Textarea = ({placeholder,value,fun,name,v_hook,newstyle,rows}) => {
  return (

      <textarea
        className={`h-[45px]  w-full text-[20px] pl-[15px]  ${newstyle}  shadow-[0px_1px_2px_0px_#dfdfdf]  outline-none`}
        type="text"
        name={name}
        //   value={title}
        placeholder={placeholder}
        value={value}
        onChange={fun}
        rows={rows}
        {...v_hook}
        //   onChange={(e) => {
        //     settitle(e.target.value);
        //   }}
      ></textarea>
    //   height: 45px;
    // border-bottom: none;
    // box-shadow: 0px 1px 2px 0px #dfdfdf;

  );
};

export default Textarea;