export const featuresHome = [
    {
    text:"বাংলা ভাষায় তথ্য।"
},
{
    text:"কোন বিষয়ের উপর হাদিস ও ক্বওল একত্র।"
},
{
    text:"প্রত্যেক হাদিস সম্পর্কে সকল পক্ষের মতামত উল্লেখসহ বিস্তারিত বিশ্লেষন পূর্বক হুকুম প্রদান।"
},
{
    text:"রাবী সম্পর্কে উলামাদের হুকুম রেফারেন্স সহ একত্রে পাওয়া ও বিশ্লেষণ পূর্বক হুকুম প্রদান।"
},
{
    text:"রাবীর উপর আরোপিত প্রত্যেক জারাহ ও তাদিলের সনদের উপর হুকুম।"
},
{
    text:"পাঠকের তথ্য সংযোজন ও সংশোধনের জন্য রিপোর্ট করা, প্রশ্ন ও তাহকিক চেয়ে আবেদন করতে পারা।"
},
{
    text:"তথ্য সমূহ আলেমগণ দ্বারা যাচাই, পুন:নীরিক্ষণ ও নিয়ন্তনাধীন থাকা।"
}
]

export const aboutData = [
    {
      ques: "আহলে তাহকিক কি?",
      ans: "আহলে তাহকিক একটি সংস্থা। যা শারঈ তথ্য যাচাই করে থাকে।",
    },
    {
      ques: "আহলে তাহকিকের উদ্দেশ্য কি?",
      ans: "শারঈ তথ্যের শুদ্ধতা ও অশুদ্ধতা নিরূপণ, অজ্ঞতা ও সন্দেহ দূরীকরণ এবং তা উলামা ও সাধারণদের মাঝে সহজবোধ্য ও সহজলভ্য করে তোলা।",
    },
    {
      ques: "আহলে তাহকিকের বিশেষত্ব কি?",
      ans: "আহলে তাহকিক নিখুঁত ও সূক্ষ্মতর ভাবে যাচাই করার চেষ্টা করে ও বিষয়ের সাথে সংশ্লিষ্ট সকল তথ্য একত্রিত করে।",
    },
    {
      ques: "আহলে তাহকিক তথ্য যাচাই প্রক্রিয়াতে কোন পদ্ধতি অনুসরণ করে?",
      ans: "আহলে সুন্নাহ ওয়াল জামআত এর পদ্ধতি অনুসরণ করে থাকে।",
    },

  ];