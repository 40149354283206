import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Cardbtn from '../Cardbtn'

const Rajulcard = ({item}) => {
  return (
    <div className={`rajulCard mb-5 m-auto shadow-[-1px_0px_6px_2px_#f3f3f3] rounded-[3px] border-l-[18px] p-3 text-normaltextcolor ${item.isRajul_type.isMuaddal &&'border-l-[#13ffeb]' || item.isRajul_type.isMajrooh &&'border-l-reddish'|| item.isRajul_type.isMajhool &&'border-l-[#d9ff00]'}`}>
      <div className="flex gap-4 mb-1 top justify-between">
        <div className="idd"><p className="id_no flex gap-2 w-fit font-[kalpurushansi] text-xl md:text-xl ">
      <FontAwesomeIcon className="rajulicon " icon="fa-solid fa-id-badge" /> {item.id <10 && 0}{item.id}</p></div>
      
        <div className="  ">
            
          
          <div className="date ">
          <p className="font-[kalpurushansi] bg-theme text-white text-[16px] p-[0px_14px] rounded-sm h-fit">{!item.bd_date.birth  ? <span>অজ্ঞাত</span>: item.bd_date.birth } - {!item.bd_date.death  ? <span>অজ্ঞাত</span> : item.bd_date.death } wn: </p> 
          </div>
           
        

        </div>
      </div>
      <div className="flex flex-col items-end gap-1 f_namesand_birth_death ">
        <Link to={`/narrators/${item.id}`}> <p  className="font-[arabickufi] hover:underline hover:underline-offset-[10px] text-lg md:text-2xl " >{item.f_name.arabic}</p></Link>
        <p className=" text-[18px]  font-[Scheherazade] md:text-[24px] text-right pb-[2px] ">ٳسم: {item.name.arabic}</p>
       
        <p className="text-xl md:text-2xl">{item.f_name.bangla}</p>
                
      </div>
      <div className="btns justify-start gap-2 flex tags">
            {item.isStarred && <div className="star"><FontAwesomeIcon
            className="text-[#727272] text-[1rem] "
            icon={faStar}
          /></div>}
          {item.isSahabi && <p className="bg-reddish text-white text-[14px] md:text-[16px] p-[0px_14px] rounded-sm h-fit">সাহাবী</p>}

          <Cardbtn value={item.isRajul_type.isMajrooh} color='bg-reddish' text='মাজরুহ' />
          <Cardbtn value={item.isRajul_type.isMuaddal} color='bg-reddish' text='মুআদ্দাল' />
          <Cardbtn value={item.isRajul_type.isMajhool} color='bg-reddish' text='মাজহুল' />

          {item.isRajul_type.isMixed && <p className="bg-reddish text-white text-[14px] md:text-[16px] p-[0px_14px] rounded-sm h-fit">মিশ্রিত</p>}
          {item.isMudallis && (
            <p className="bg-reddish text-white text-[14px] md:text-[16px] p-[0px_14px] rounded-sm h-fit">
              <>মুদাল্লিস</>
            </p>
          )}

            </div>
      {/* <div className="fullname">
        
        <p className=" md:text-2xl border-b border-[#c1c1c1] pb-[2px]">নাম: {item.name.bangla}</p>
      </div> */}
      <div className="mt-3 btns">
       
      </div>

    </div>
  )
}

export default Rajulcard