import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion'
import { Link } from 'react-router-dom'


const letters = [{
    letter:'ا',
    name:'হামযাহ'
},
{
    letter:'ب',
    name:'বা'
},{
    letter:'ت',
    name:'তা'
},{
    letter:'ث',
    name:'ছা'
},{
    letter:'ج',
    name:'জিম'
},{
    letter:'ح',
    name:'হা'
},{
    letter:'خ',
    name:'খা'
},{
    letter:'د',
    name:'দাল'
},{
    letter:'ذ',
    name:'যাল'
},{
    letter:'ر',
    name:'রা'
},{
    letter:'ز',
    name:'যা'
},{
    letter:'س',
    name:'সিন'
},{
    letter:'ش',
    name:'শিন'
},{
    letter:'ص',
    name:'স্বোয়াদ'
},{
    letter:'ض',
    name:'দোয়াদ'
},{
    letter:'ع',
    name:'আইন'
},{
    letter:'غ',
    name:'গাইন'
},{
    letter:'ط',
    name:'ত্বা'
},{
    letter:'ظ',
    name:'য্বয়া'
},{
    letter:'ف',
    name:'ফা'
},{
    letter:'ك',
    name:'কাফ'
},{
    letter:'ق',
    name:'ক্বাফ'
},{
    letter:'ل',
    name:'লাম'
},{
    letter:'م',
    name:'মিম'
},{
    letter:'ن',
    name:'নুন'
},{
    letter:'ه',
    name:'হা'
},{
    letter:'و',
    name:'ওয়াও'
},{
    letter:'ى',
    name:'ইয়া'
},

 ]

const Letter = () => {
  return (
    <>
    <Accordion>
          
          <AccordionItem className='bg-[#e8ff1b]'>
          {({open}) => (<><AccordionHeader className={`accordion w-full flex justify-between bg-theme text-[20px] shadow-[0px_2px_5px_0px_#d9d9d9] items-center text-white py-[8px] px-[15px] rounded-[3px]  hover:bg-[#30424e] transition-all duration-300 ease-in-out ${open ? "bg-[#30424e]" : "bg-theme"}`}>
                 <><h3 className={`accordion-title `}>অক্ষর ফিল্টার </h3><p style={{ marginLeft: "20px" }}>{open ? <FontAwesomeIcon icon="fa-solid fa-caret-up" /> : <FontAwesomeIcon icon="fa-solid fa-caret-down" />}</p></> 
              </AccordionHeader>
      
              <AccordionBody>
              <div className='flex mt-2 gap-1 flex-wrap'>
        {letters.map((i)=> <Link className={` p-[1px_17px] text-center bg-[#ffffff] rounded-sm text-[#303030]  text-[14px] md:text-[18px] hover:bg-theme hover:text-white shadow-[0px_1px_3px_0px_#e3e3e3]`} to={`?alphabet=${i.letter}`}> <p>{i.letter}</p> <p>{i.name}</p></Link> )}
        
        
    </div>
                  
              </AccordionBody></>)}
              
          </AccordionItem>
      </Accordion>
    
    </>
  )
}

export default Letter