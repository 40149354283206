import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Banner = () => {
  return (
    <div className="box md:w-[60%] shadow-[#edebeb_1px_1px_2px_2px] rounded-lg   mb-2 gap-3 flex">
          <div className="ic pt-[0.2rem] md:pt-4 w-[100px] text-white bg-theme text-center text-[22px] md:text-[26px]">
            <FontAwesomeIcon icon="fa-solid fa-list-check" />
          </div>
          <p className="p-[0.2rem] md:p-2 text-[#3e3d3d]  text-[1rem]">
            আহলে তাহকিক এর কিছু বিশেষত্ব রয়েছে যা প্রসিদ্ধ অ্যাপসগুলোতে নেই।
            যেমন: মাকতাবাতুশ শামিলাহ, জাওয়ামিউল কালিম, হাদিস ট্রান্সমিটার, বাংলা
            হাদিস বিডি। বিশেষত্বগুলো নিম্নে তুলনা করা হলো:
          </p>
        </div>
  )
}

export default Banner