import React from 'react'

const Inputtitle = ({title}) => {
  return (
    <>
    <label className='i_title w-fit bg-[#ffffff] p-[3px_15px] rounded-[15px] mb-3 text-[17px] text-[#5a5555] shadow-[0px_0px_7px_0px_#e3e3e3] font-[600] inline-block '>{title}:</label>
    </>

    // border-radius: 15px;

  )
}

export default Inputtitle