import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import  Accordions from "../test/components/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import api from "../api/baseapi";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import Loader from "../components/Loader";
import Cardbtn from '../components/Cardbtn'
import Qaulsofimams from "../components/Qaulsofimams";

const Rajuldetails = () => {
  const { id } = useParams();

  useEffect(() => {
    api.get(`/rajul/${id}`).then((res) => {
      // setid(res.data.rajul);
      setname(res.data.rajul.name);
      setf_name(res.data.rajul.f_name);
      setbd_date(res.data.rajul.bd_date);
      setq_name(res.data.rajul.q_name);
      setisRajulType(res.data.rajul.isRajul_type);
      setgrade(res.data.rajul.grade);
      setisFissittah(res.data.rajul.isFisSittah);
      setbooks(res.data.rajul.books);
      setisMudallis(res.data.rajul.isMudallis);
      setisMukhtalit(res.data.rajul.isMukhtalit);
      setisSahabi(res.data.rajul.isSahabi);
      setisStarred(res.data.rajul.isStarred);
      setbooks(res.data.rajul.books);
      setteachers(res.data.rajul.teachers);
      setstudents(res.data.rajul.students);
      setTadeellist(res.data.rajul.tadeellist);
      setJarahlist(res.data.rajul.jarahlist);
      setOtherlist(res.data.rajul.otherlist);
      setdescription(res.data.rajul.description);
    });
  }, []);
  
  const [name, setname] = useState({ arabic: "", bangla: "" });
  const [f_name, setf_name] = useState({ arabic: "sefsef", bangla: "sefesf" });
  const [q_name, setq_name] = useState("");
  const [bd_date, setbd_date] = useState({ birth: 196, death: 256 });
  const [isStarred, setisStarred] = useState(false);
  const [tabqa, settabqa] = useState(0);
  const [isSahabi, setisSahabi] = useState(false);
  const [isRajul_type, setisRajulType] = useState({
    isMuaddal: true,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  });

  const [grade, setgrade] = useState("sdfsdf");
  const [isMudallis, setisMudallis] = useState(true);
  const [isMukhtalit, setisMukhtalit] = useState(true);
  const [isFissittah, setisFissittah] = useState({
    bukhari: false,
    muslim: false,
    tirmizi: false,
    abuDawud: false,
    nasaee: false,
    ibnMajah: false,
  });
  const [books, setbooks] = useState([{ name: "", link: "" }]);
  const [teachers, setteachers] = useState(["sefsefsfe", "sefsef"]);
  const [students, setstudents] = useState(["sefsefsfe", "sefsef"]);
  const [tadeellist, setTadeellist] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);
  const [jarahlist, setJarahlist] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);
  const [otherlist, setOtherlist] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);
  var jarahlistSorted = jarahlist.sort((a, b) => a.deathYear-b.deathYear);
  var tadillistSorted = tadeellist.sort((a, b) => a.deathYear-b.deathYear);
  var otherlistSorted = otherlist.sort((a, b) => a.deathYear-b.deathYear);
  const [description, setdescription] = useState("");
  const [special_desc, setspecial_desc] = useState("sdfsdf");
  const [d_link, setd_link] = useState("sdfsdf");

  // Setting Strings for input
  const [teacherstring, setteacherstring] = useState([]);
  const [studentstring, setstudentstring] = useState([]);



  const [ToggleState, setToggleState] = useState(1);

  const [open, setopen] = useState(false);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  return (!name.bangla  ?  <Loader />:
    <div>
      <div className=" m-auto p-3 md:p-4 shadow-[1px_4px_5px_2px_#f1f1f1]">
        <div className="toper text-[#444444] border-b-2 border-[#e7e7e7] mb-4 pb-4">

        <div className="flex items-center justify-end gap-2 mb-3 id_star">
          {isStarred && (
            <div className="star">
              <FontAwesomeIcon
                className="text-[#727272] text-[1.5rem]  "
                icon={faStar}
              />
            </div>
          )}

          <div className="id  bg-theme p-[2px_23px] text-[18px] text-white">
            <p className="font-[kalpurushansi]">
              {id < 10 && 0}
              {id}
            </p>
          </div>
        </div>
        <div className={`f_name text-right border-l-[8px] text-[1.5rem] md:text-4xl ${isRajul_type.isMuaddal ?'border-l-[#11ffbb]' : isRajul_type.isMajrooh?  'border-l-reddish':null} mb-3`}>
          <p className="f_name arabic font-[arabickufi] text-[1.4rem]">
            {f_name.arabic}
          </p>

          <p className="f_name bangla">
            {isStarred && <span>ইমাম</span>} {f_name.bangla}
          </p>
        
          
          
        </div>
        
          <div className="flex justify-end mb-2 bd_date">
            <p className="bd_year bg-theme text-white py-[2px] px-[14px] rounded-3xl">
              <span className="font-[kalpurushansi]">
                {bd_date.birth ? bd_date.birth: <span>অজ্ঞাত</span> } - {bd_date.death ? bd_date.death: <span>অজ্ঞাত</span> }
              </span>{" "}
              হি:
            </p>
          </div>
       

        <div className="flex gap-3">
          {isSahabi && (
            <p className="sahabi  text-black  bg-[#94ebff] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              {" "}
              সাহাবী{" "}
            </p>
          )}
          {isRajul_type.isMuaddal && (
            <p className="sahabi text-black bg-[#11ffbb] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              <>মুআদ্দাল</>
            </p>
          )}
          <Cardbtn value={isRajul_type.isMajrooh} color={'bg-reddish'} text={"মাজরুহ"} />
          <Cardbtn value={isMudallis} color={'bg-reddish'} text={"মুদাল্লিস"} />
          {/* {isRajul_type.isMajrooh && (
            <p className="sahabi text-black bg-[#ff3232] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              <>মাজরুহ</>
            </p>
          )} */}
          {isRajul_type.isMajhool && (
            <p className="sahabi text-black bg-[#11ffbb] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              <>মাজহুল</>
            </p>
          )}
          {isRajul_type.isMixed && (
            <p className="sahabi text-black bg-[#11ffbb] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              <>মিশ্রিত</>
            </p>
          )}

          {isMukhtalit && (
            <p className="sahabi text-black bg-[#11ffbb] py-[3px] px-4 font-[600] shadow-[0px_0px_3px_0px_#cfcfcf]">
              <>মুখতালিত</>
            </p>
          )}
        </div>
        {/* <div className="namediv border-b border-[#a7a7a7] mb-3">
          <div className="full_naem w-fit md:text-2xl ">
            <p className="bangla">
              <span>নাম:</span> {name.bangla}{" "}
            </p>
            <p className="text-right arabic">
              <span>اسم:</span> {name.arabic}
            </p>
            {q_name && (
              <div className="Kuniyat">
                <p className="kuniyat_bangla">কুনিয়াত: {q_name}</p>
              </div>
            )}
          </div>
        </div> */}

        </div>
        
        
        <div className="flex muddallis_kutubesittah bg-[#ffffff] flex-col md:flex-row pb-[15px] md:pb-0  ">
          <div className="hukum h-fit w-full  bg-white md:p-[15px_10px]  text-[22px] flex-[1.5]">
            {/* <p>হুকুম: {grade}</p>{" "} */}
            <div className="second ">
              
          <div className="names text-[1rem] md:text-[1.2rem]">
          <p className=" bg-theme rounded-[3px] w-fit py-[3px] px-5 text-white mb-4">বর্ণনাকারীর বিবরণ:</p>

          <p className="border-b mb-[11px] border-[#e7e7e7]"><span className="font-bold">নাম (আরবী):</span> {name.arabic}</p>
          <p className="border-b mb-[11px] border-[#e7e7e7]"><span className="font-bold">নাম (বাংলা):</span> {name.bangla}</p>
          {q_name && <p className="border-b mb-[11px] border-[#e7e7e7]"><span className="font-bold">কুনিয়াত:</span> {q_name}</p>}
          
            <p className="border-b mb-[11px] border-[#e7e7e7]"><span className="font-bold">জীবনকাল:</span> {" "} 
               <span className="font-[kalpurushansi]">
               {bd_date.birth ? bd_date.birth: <span>অজ্ঞাত</span> } - {bd_date.death ? bd_date.death: <span>অজ্ঞাত</span> }
              </span>{" "}
              হি:
            </p>
   
        
          
          <p className="border-b mb-[11px] border-[#e7e7e7]"><span className="font-bold">হুকুম:</span> {grade}।</p>
          </div>
          
        </div>
          </div>

          
          <div className="flex gap-3 kutubesittah flex-[1] justify-center items-center ">
            <div className="left">

              <div className="bukhari mb-1 bg-theme flex p-1">
              <div className="h-[25px]  w-[25px]  text-2xl text-theme flex justify-center items-center  bg-white ">{isFissittah.bukhari ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" /> }  </div>


              <p className=" gap-2 items-center px-4 text-white font-[600] ]">বুখারী</p>

              </div>
              <div className="bukhari mb-1 bg-theme flex p-1">
              <div className="h-[25px]  w-[25px]  text-2xl text-theme flex justify-center items-center  bg-white ">{isFissittah.tirmizi ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" /> }  </div>


              <p className=" gap-2 items-center px-4 text-white font-[600] ]">তিরমিযী</p>

              </div>

              <div className="bukhari mb-1 bg-theme flex p-1">
              <div className="h-[25px]  w-[25px]  text-2xl text-theme flex justify-center items-center  bg-white ">{isFissittah.nasai ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" /> }  </div>


              <p className=" gap-2 items-center px-4 text-white font-[600] ]">নাসাঈ</p>

              </div>

              
            </div>
            <div>
            <div className="bukhari mb-1 bg-theme flex p-1">
              <div className="h-[25px]  w-[25px]  text-2xl text-theme flex justify-center items-center  bg-white ">{isFissittah.muslim ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" /> }  </div>


              <p className=" gap-2 items-center px-4 text-white font-[600] ]">মুসলিম</p>

              </div>
              <div className="bukhari mb-1 bg-theme flex p-1">
              <div className="h-[25px]  w-[25px]  text-2xl text-theme flex justify-center items-center  bg-white ">{isFissittah.abuDawud ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" /> }  </div>


              <p className=" gap-2 items-center px-4 text-white font-[600] ]">আবূ দাঊদ</p>

              </div>

              <div className="bukhari mb-1 bg-theme flex p-1">
              <div className="h-[25px]  w-[25px]  text-2xl text-[#4a4a4a] flex justify-center items-center  bg-white ">{isFissittah.ibnMajah ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" /> }  </div>


              <p className=" gap-2 items-center px-4 text-white font-[600] ]">ইবন মাজাহ</p>

              </div>
            </div>
          </div>
        </div>
        {books && books[0].name && (
          <div className="booklist">
            <Accordion
              title="বই এর তালিকা"
              content={
                <AnimatePresence>
                  <div
                    // key="box"
                    //   layout
                    //   initial={{ y: "0%", opacity: 0 }}
                    //   animate={{ y:0, opacity: 1 }}
                    //   exit={{y:"0%", opacity: 0 }}
                    //   transition={{
                    //     duration:0.2, ease: "easeOut"

                    //   }}
                    className="container transition-[height_200ms] scroll-smooth"
                  >
                    <ul>
                      {books &&
                        books.map((i, x) => (
                          <li className="border-b border-[#d1d1d1] px-5 py-2">
                            {i.name}
                          </li>
                        ))}
                    </ul>
                  </div>
                </AnimatePresence>
              }
            />
          </div>
        )}

        {(teachers.length !==0 || students.length !==0) && <div className="mb-2 taps">
        <Accordion 
        // alwaysOpen={true}
        >
        <AccordionItem 
        // isActive={true}
        >
        {({open}) => (<><AccordionHeader className={`accordion justify-between bg-theme w-full gap-2 flex p-[10px_18px] text-[1.2rem] md:text-[1.5rem] rounded-[7px_7px_0px_0px] text-white hover:bg-[#30424e] transition-all duration-300 ease-in-out ${open ? "bg-[#30424e]" : "bg-theme"}`}><p >উস্তায ও ছাত্র তালিকা:</p><span style={{ marginLeft: "20px" }}>{open ? <FontAwesomeIcon icon="fa-solid fa-caret-up" /> : <FontAwesomeIcon icon="fa-solid fa-caret-down" />}</span></AccordionHeader>
        <AccordionBody>
        <Tabs
        selectedTabClassName="bg-[#90f5ff]">
    <TabList>
      <Tab>উস্তায তালিকা:</Tab>
      <Tab>ছাত্র তালিকা:</Tab>
    </TabList>

    <TabPanel>

    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>

                          {teachers.map((i,index)=>  <tr className="flex align-top ">
                            <td className="flex gap-1 "> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            
                            </td>

                    
                            <td className="flex flex-col gap-1 tadeel">
                              <tr className="flex ">
                              <p className="underline decoration-1 decoration-[#a7a7a7] underline-offset-8">{i}</p> 
                              </tr>
                              
                             
                        

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div>
    </TabPanel>
    <TabPanel>
    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>

                          {students.map((i,index)=>  <tr className="flex align-top ">
                            <td className="flex gap-3 "> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            
                            </td>

                    
                            <td className="flex flex-col gap-1 tadeel">
                              <tr className="flex ">
                              <p className="underline decoration-1 decoration-[#a7a7a7] underline-offset-8">{i}</p> 
                              </tr>
                              
                             
                        

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div> 
    </TabPanel>
  </Tabs>
        </AccordionBody></>)}
        

        </AccordionItem>
        </Accordion>
        
        </div>}

        {/* <div>
          <Accordion
            title="উস্তায ও ছাত্রের তালিকা:"
            fun={toggleTab}
            content={
              <Tabs>
    <TabList>
      <Tab>Title 1</Tab>
      <Tab>Title 2</Tab>
    </TabList>

    <TabPanel>
    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>

                          {teachers.map((i,index)=>  <tr className="flex align-top ">
                            <td className="flex gap-3 "> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            
                            </td>

                    
                            <td className="flex flex-col gap-1 tadeel">
                              <tr className="flex ">
                               {i}
                              </tr>
                              
                             
                        

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div>
    </TabPanel>
    <TabPanel>
    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>

                          {students.map((i,index)=>  <tr className="flex align-top ">
                            <td className="flex gap-3 "> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            
                            </td>

                    
                            <td className="flex flex-col gap-1 tadeel">
                              <tr className="flex ">
                               {i}
                              </tr>
                              
                             
                        

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div> 
    </TabPanel>
  </Tabs>

            }
          />
        </div> */}

        <Qaulsofimams array={tadeellist} sortedarray={tadillistSorted} title={'যারা তাদীল করেছেন:'} />
         {/* { tadillistSorted[0].name &&  <div>
            <Accordions
              title="যারা তাদীল করেছেন:"
              content={
                  <div
                    className="transition-[height_200ms] scroll-smooth"
                  >
                    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>
                          <tr className="hidden md:flex ">
                            <th className="flex-[2]">ইমামের নাম:</th>
                            <th className="flex-[0.5]">মৃত্যু সন:</th>
                            <th className="flex-[3]">বক্তব্য:</th>
                          </tr>
                          {tadillistSorted.map((i,index)=>  <tr className="flex flex-col align-top md:flex-row border-b border-[#dbdbdb]">
                            <td className="flex-[2] flex gap-3"> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            <p className="block whitespace-nowrap md:hidden"><span className="font-['kalpurushansi'] ">{i.deathYear}</span>  হিঃ</p> 
                            </td>

                            <td className="hidden md:block flex-[0.5] "   > <p className="whitespace-nowrap"><span className="font-['kalpurushansi']">{i.deathYear}</span>  হিঃ</p> ‍</td>
                            <td className="tadeel flex-[3] flex flex-col gap-1">
                              <tr className="flex justify-end ">
                               {i.quote.arabic}
                              </tr>
                              <tr className="bangla">
                                {i.quote.bangla}
                              </tr>
                              <tr className="bangla">
                                <p className="ref p-[1px_13px] text-[1.1rem] w-fit font-bold shadow-[0_0_3px_0px_#e1e1e1] bg-[#b5ffd6]">{i.ref}</p>

                               
                              </tr>
                              <tr className="bangla "><p className="grade bg-[#7bffb6] w-fit p-[1px_13px] text-[1rem] text-[#353535]">{i.grade}</p></tr>

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div>
                  </div>

              }
            />
          </div>
          
        } */}

        <Qaulsofimams array={jarahlist} sortedarray={jarahlistSorted} title={'যারা জারাহ করেছেন:'} />
        <Qaulsofimams array={otherlist} sortedarray={otherlistSorted} title={'অন্যান্য মন্তব্য:'} />


{/* { otherlistSorted[0]?.name &&  <div>
            <Accordions
              title="অন্যান্য মন্তব্য:"
              content={
                  <div
                    className="transition-[height_200ms] scroll-smooth"
                  >
                    <div className="tadeels">
                      <table id="customers" className="md:text-[1.3rem]">
                        <tbody>
                          <tr className="hidden md:flex ">
                            <th className="flex-[2]">ইমামের নাম:</th>
                            <th className="flex-[0.5]">মৃত্যু সন:</th>
                            <th className="flex-[3]">বক্তব্য:</th>
                          </tr>
                          {otherlistSorted.map((i,index)=>  <tr className="flex flex-col align-top md:flex-row border-b border-[#dbdbdb]">
                            <td className="flex-[2] flex gap-3"> <span className="font-['kalpurushansi'] h-fit bg-[#e7e7e7] px-3 shadow-[0px_0px_6px_0px_#f7eaea]">{index+1}</span> {i.name} 
                            <p className="block whitespace-nowrap md:hidden"><span className="font-['kalpurushansi'] ">{i.deathYear}</span>  হিঃ</p> 
                            </td>

                            <td className="hidden md:block flex-[0.5] "   > <p className="whitespace-nowrap"><span className="font-['kalpurushansi']">{i.deathYear}</span>  হিঃ</p> ‍</td>
                            <td className="tadeel flex-[3] flex flex-col gap-1">
                              <tr className="flex justify-end ">
                               {i.quote.arabic}
                              </tr>
                              <tr className="bangla">
                                {i.quote.bangla}
                              </tr>
                              <tr className="bangla">
                                <p className="ref p-[1px_13px] text-[1.1rem] w-fit font-bold shadow-[0_0_3px_0px_#e1e1e1] bg-[#b5ffd6]">{i.ref}</p>

                               
                              </tr>
                              <tr className="bangla "><p className="grade bg-[#7bffb6] w-fit p-[1px_13px] text-[1rem] text-[#353535]">{i.grade}</p></tr>

                            </td>
                          </tr>)}
                         

                        </tbody>
                      </table>
                    </div>
                  </div>

              }
            />
          </div>
          
        } */}


        <div className="description">
          <p>{description}</p>
        </div>
      </div>
    </div>


  );
};

export default Rajuldetails;
