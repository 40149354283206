import React from 'react'

const Modal = () => {
  return (<>
    
        <div className="div1 absolute top-0 z-50 left-0  w-[100vw] h-[100vh] bg-[red]"></div>
        <div className="div2 bg-slate-500 absolute z-[52]  left-0">
            <button><p>Delete</p></button>
            <button><p>Cancel</p></button>
        </div>
        </>

    
  )
}

export default Modal