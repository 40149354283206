import { findAllByDisplayValue } from "@testing-library/react";
import React, { useState } from "react";
import Inputfield from "../components/Inputfield";
import Inputtitle from "../components/Inputtitle";
import {
  addstringtoarray,
  handleAddClick,
  handleRemoveClick,
  hookAddinput,
} from "../hooks/hookAddinput";

const Testrajul = () => {
  const [name, setname] = useState({ arabic: "Imam bukhari", bangla: "Imam bukhari" });
  const [f_name, setf_name] = useState({ arabic: "Imam bukhari", bangla: "Imam bukhari" });
  const [q_name, setq_name] = useState("Bukhari");
  const [bd_date, setbd_date] = useState({birth:'344', death:'3422'});
  const [isStarred, setisStarred] = useState(true);
  const [tabqa, settabqa] = useState(5);
  const [isSahabi, setisSahabi] = useState(false);
  const [isRajul_type, setisRajulType] = useState({isMuaddal:true, isMajrooh:false, isMajhool:false, isMixed:false});
  const [grade, setgrade] = useState();
  const [isMudallis, setisMudallis] = useState(false);
  const [isMukhtalit, setisMukhtalit] = useState(false);
  const [isFissittah, setisFissittah] = useState({bukhari:true, muslim: false, tirmizi:true, abu_dawud: false, ibn_majah:false});
  const [booklist, setbooklist] = useState([{ name: "sahih bukhari", link: "www.sahihbukhari" }]);
  const [teacherlist, setTeacherlist] = useState([]);
  const [studentlist, setStudentlist] = useState([]);
  // const [tadeellist, setTadeellist] = useState([
  //   { name: "", quote: "", ref: "", grade: "" },
  // ]);

  const [tadeellist, setTadeellist] = useState([
    { name: "Imam Ahmad", quote: { arabic: "He is so good", bangla: "He is so good" }, ref: "Tarikhe Baghdad", grade: "Sahih" },{ name: "Imam Ahmad", quote: { arabic: "He is so good", bangla: "He is so good" }, ref: "Tarikhe Baghdad", grade: "Sahih" },
  ]);
  // const [jarahlist, setJarahlist] = useState([
  //   { name: "", quote: "", ref: "", grade: "" },
  // ]);
  // const [jarahlist, setJarahlist] = useState([
  //   { name: "", quote: { arabic: "", bangla: "" }, ref: "", grade: "" },
  // ]);
  const [jarahlist, setJarahlist] = useState(
    {name:"", quote: { arabic: "", bangla: "" }, ref: "", grade: "" },
  );
  const [user, setUser] = useState({
    email: "",
    password: "",
    settings: { subscribe: "" },
  });
  const [description, setdescription] = useState({});
  const [special_desc, setspecial_desc] = useState({});
  const [d_link, setd_link] = useState({});

  // Setting Strings for input
  const [teacherstring, setteacherstring] = useState([]);
  const [studentstring, setstudentstring] = useState([]);
  const [options, setOptions] = useState({
    0: "",
    2: {
      title: "",
      farhan: "",
    },
    3: "",
  });

  
  const handleChangee = (e,state,setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const newfun = (state,setState,event,index,x)=>{
    const { name, value, id } = event.target;
    // const new = id
    // const far = id
  const list = [...state];
  // list[index][name][id] = value;
    //  const item = {...x, ["quote"[event.target.name]] : event.target.value, }
     const item = {...x, quote:{...x.quote, [event.target.name]: event.target.value,}  }
 
      
  list[index] = item;
  setState(list);
  }
    // setJarahlist({
  //   ...jarahlist,
  //   quote: {
  //     ...jarahlist.quote,
  //     bangla: e.target.value,
  //   },
  // })

  console.log("rajul type new", name);
  // console.log("user new", user);
  // console.log("jarah new", jarahlist);
  // console.log("tadeel new", tadeellist);
  // console.log('this is stricng', string)
  const addAndClearfield = (string, setstring, state, setstate) => {
    addstringtoarray(string, state, setstate);
    setstring("");
  };
  const handleChange = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const objOfBtns = (e, state, setstate,val) => {
    setstate({
      ...state,
      [e.target.name]: val,
    });
  };

  

  return (
    <div >
      <div  className="h-[80px]" >
        <div className="fixed right-0 flex justify-end bg-[#e7ffff] shadow-[4px_0px_5px_0px_#efefef] mb-9 w-full h-12 ">
          <button className="bg-slate-600">সাবমিট করুন</button>
        
      
      </div>

      </div>
      <div className="container m-auto px-10">
      <div className="mb-5 single_input nameInput">
        <Inputtitle title={"রাবীর নাম"} />
        <div className="flex flex-col gap-4 md:flex-row">
          <Inputfield
            placeholder={"আরবী"}
            name="arabic"
            value={name.arabic}
            fun={(e) => handleChange(e, name, setname)}
          />
          <Inputfield
            placeholder={"বাংলা"}
            name="bangla"
            value={name.bangla}
            fun={(e) => handleChange(e, name, setname)}
          />
        </div>
      </div>

      <div className="mb-5 single_input Famous nameInput">
        <Inputtitle title={"প্রসিদ্ধ নাম"} />
        <div className="flex flex-col gap-4 md:flex-row">
          <Inputfield
            placeholder={"আরবী"}
            name="arabic"
            value={f_name.arabic}
            fun={(e) => handleChange(e, f_name, setf_name)}
          />
          <Inputfield
            placeholder={"বাংলা"}
            name="bangla"
            value={f_name.bangla}
            fun={(e) => handleChange(e, f_name, setf_name)}
          />
        </div>
      </div>

      <div className="mb-5 single_input">
        <div className="flex flex-col gap-4 md:flex-row">
          <div>
            <Inputtitle title={"কুনিয়াত"} />
            <Inputfield
              placeholder={"বাংলা"}
              value={q_name}
              fun={(e) => setq_name(e.target.value)}
            />
          </div>

          <div>
            <Inputtitle title={"জন্ম-মৃত্যু সাল"} />
            <div className="flex">
              <Inputfield
                placeholder={"জন্মসাল"}
                name="birth"
                value={bd_date.birth}
                fun={(e) => handleChange(e, bd_date, setbd_date)}
              />
              <Inputfield
                placeholder={"মৃত্যুসাল"}
                name="death"
                value={bd_date.death}
                fun={(e) => handleChange(e, bd_date, setbd_date)}
              />
            </div>
          </div>
          <div className="flex buttons">
            <button
              className={`w-fit ${
                isSahabi ? "bg-[#7de7f3]": 'bg-slate-500'
              }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisSahabi(!isSahabi)}
            >
              সাহাবী
            </button>

            <button
              className={`w-fit ${
                isStarred ? "bg-[#7de7f3]": 'bg-slate-500'
              }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisStarred(!isStarred)}
              value={isStarred}
            >
              স্টার
            </button>
            {/* <button>Is starred</button> */}
            <div className="tabqah">
            <Inputtitle title={"তাবকাহ"} />
            <Inputfield placeholder={'তাবকাহ'} value={tabqa} fun={(e)=>settabqa(e.target.value)} />
            </div>
            
          </div>
        </div>
      </div>


      <div className="hukums flex flex-col md:flex md:flex-row gap-4">
      
        <div>
          <Inputtitle title={'গ্রহণযোগ্যতা'} />
          <div className="flex">

            {Object.entries(isRajul_type).map(([key, val], i) => (
    <button className={`${val? 'bg-[#a9f7ff]' : 'bg-slate-400'} p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`} name={key} onClick={(e)=>objOfBtns(e, isRajul_type, setisRajulType, !val )}  key={i}>
      
        {key ==='isMuaddal' &&  <>মুতাদিল</> }
        {key ==='isMajrooh' && <>মুতাদিল</>}
        {key ==='isMajhool' && <>মুতাদিল</>}
        {key ==='isMixed' && <>মুতাদিল</>}
    </button >
))}

          </div>
        </div>


        <div>
          <Inputtitle title={'কুতুবে সিত্তাহ'} />
          <div className="flex">

            {Object.entries(isFissittah).map(([key, val], i) => (
    <button className={`${val? 'bg-[#a9f7ff]' : 'bg-slate-400'} p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`} name={key} onClick={(e)=>objOfBtns(e, isFissittah, setisFissittah, !val )}  key={i}>
      
        {key ==='bukhari' &&  <>বুখারী</> }
        {key ==='muslim' && <>মুসলিম</>}
        {key ==='tirmizi' && <>তিরমিযি</>}
        {key ==='abu_dawud' && <>আবূ দাঊদ</>}
        {key ==='nasai' && <>নাসাঈ</>}
        {key ==='ibn_majah' && <>ইবনে মাজাহ</>}
    </button >
))}

          </div>
        </div>
        <div className="flex buttons">
            <button
              className={`w-fit ${
                isMudallis ? "bg-[#7de7f3]": 'bg-slate-500'
              } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisMudallis(!isMudallis)}
            >
              মুদাল্লিস
            </button>

            <button
              className={`w-fit ${
                isMukhtalit ? "bg-[#7de7f3]": 'bg-slate-500'
              }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisMukhtalit(!isMukhtalit)}
            >
              মুখতালিত
            </button>
            {/* <button>Is starred</button> */}
            <div className="tabqah">
            <Inputtitle title={"তাবকাহ"} />
            <Inputfield placeholder={'তাবকাহ'} fun={(e)=>settabqa(e.target.value)} />
            </div>
            
          </div>
        
      
      </div>

      <div className="string hukum">
      <div className="hukum">
          <Inputtitle title={'হুকুম'} />
          <Inputfield placeholder={'হুকুম'} fun={(e)=>setgrade(e.target.value)} />

        </div>

      </div>

      <div className="mb-5 bookscontainer">
        <div className="div">
          <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
            বই এর তালিকা:
          </p>
          {booklist.map((x, i) => {
            return (
              <div key={i} className="box first-letter">
                <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    name="name"
                    value={x.name}
                    fun={(e) => hookAddinput(booklist, setbooklist, e, i)}
                  />

                  <Inputfield
                    placeholder={"লিংক"}
                    value={x.link}
                    name="link"
                    fun={(e) => hookAddinput(booklist, setbooklist, e, i)}
                  />
                </div>

                <div className="btn-box">
                  {booklist.length !== 1 && (
                    <button
                      className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleRemoveClick(booklist, setbooklist, i)
                      }
                    >
                      Remove
                    </button>
                  )}
                  {booklist.length - 1 === i && (
                    <button
                      className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleAddClick(booklist, setbooklist, {
                          name: "",
                          link: "",
                        })
                      }
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mb-5 teacherstudentscontainer flex">
        <div className="div teacherlist">
          <Inputtitle title={"উস্তাযের তালিকা"} />
          <Inputfield
            placeholder={"ইমামের নাম"}
            fun={(e) => setteacherstring(e.target.value)}
            value={teacherstring}
          />

          <button
            onClick={() =>
              teacherstring.length === 0
                ? alert(" Please Enter student name")
                : //  addstringtoarray(string,studentlist, setStudentlist)
                  addAndClearfield(
                    teacherstring,
                    setteacherstring,
                    teacherlist,
                    setTeacherlist
                  )
            }
          >
            add input
          </button>

          {teacherlist.map((x, i) => {
            return (
              <div key={i} className="box first-letter">
                <p>{x}</p>
                {/* <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    // fun={(e) => addstringtoarray(x,teacherlist, setTeacherlist)}
                  />

                </div> */}

                <div className="btn-box">
                  {teacherlist.length !== 0 && (
                    <button
                      className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleRemoveClick(teacherlist, setTeacherlist, i)
                      }
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="div studentlist">
          <Inputtitle title={"ছাত্র তালিকা"} />
          <Inputfield
            placeholder={"ইমামের নাম"}
            fun={(e) => setstudentstring(e.target.value)}
            value={studentstring}
          />

          <button
            onClick={() =>
              studentstring.length === 0
                ? alert(" Please Enter student name")
                : //  addstringtoarray(string,studentlist, setStudentlist)
                  addAndClearfield(
                    studentstring,
                    setstudentstring,
                    studentlist,
                    setStudentlist
                  )
            }
          >
            add input
          </button>

          {studentlist.map((x, i) => {
            return (
              <div key={i} className="box first-letter">
                <p>{x}</p>
                <div className="flex flex-col md:flex-row"></div>

                <div className="btn-box">
                  {studentlist.length !== 0 && (
                    <button
                      className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleRemoveClick(studentlist, setStudentlist, i)
                      }
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mb-5 tadeellistcontainer">
        <div className="div">
          <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
            যে ইমামগণ তা’দীল করেছেন:
          </p>
          {tadeellist.map((x, i) => {
            return (
              <div key={i} className="box first-letter">
                <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    name="name"
                    value={x.name}
                    fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                    // fun={(e) => {newfun(tadeellist, setTadeellist, e, i,x)}}
                  />

                  <Inputfield
                    placeholder={"arabic বক্তব্য"}
                    value={x.quote.arabic}
                    id="arabic"
                    name="arabic"
                    // fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                    fun={(e) => {newfun(tadeellist, setTadeellist, e, i,x)
                      // setJarahlist(
                      //   ...jarahlist,
                      //   ...x,
                      //   quote: {
                      //     ...x.quote,
                      //     arabic: e.target.value,
                      //   },
                      // );
                    }}
                  />
                  {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                  /> */}

                  <Inputfield
                    placeholder={"রেফারেন্স"}
                    value={x.ref}
                    name="ref"
                    fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                  />
                  <Inputfield
                    placeholder={"মান"}
                    value={x.grade}
                    name="grade"
                    fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                  />
                </div>

                <div className="btn-box">
                  {tadeellist.length !== 1 && (
                    <button
                      className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleRemoveClick(tadeellist, setTadeellist, i)
                      }
                    >
                      Remove
                    </button>
                  )}
                  {tadeellist.length - 1 === i && (
                    <button
                      className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleAddClick(tadeellist, setTadeellist, {
                          name: "",
                          quote: {arabic:"", bangla:""},
                          ref: "",
                          grade:""
                        })
                      }
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className="mb-5 jarahlistcontainer">
        <div className="div">
          <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
            যে ইমামগণ জারাহ করেছেন:
          </p>
          {jarahlist.map((x, i) => {
            return (
              <div key={i} className="box first-letter">
                <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    name="name"
                    value={x.name}
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  />

                  <Inputfield
                    placeholder={"বক্তব্য"}
                    value={x.quote}
                    name="quote"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  />

                  <Inputfield
                    placeholder={"রেফারেন্স"}
                    value={x.ref}
                    name="ref"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  />
                  <Inputfield
                    placeholder={"মান"}
                    value={x.grade}
                    name="grade"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  />
                </div>

                <div className="btn-box">
                  {jarahlist.length !== 1 && (
                    <button
                      className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleRemoveClick(jarahlist, setJarahlist, i)
                      }
                    >
                      Remove
                    </button>
                  )}
                  {jarahlist.length - 1 === i && (
                    <button
                      className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleAddClick(jarahlist, setJarahlist, {
                          name: "",
                          quote: "",
                          ref: "",
                        })
                      }
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      {/* <div className="mb-5 jarahlistcontainer">
        <div className="div">
          <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
            যে ইমামগণ জারাহ করেছেন:
          </p>
          {/* <input
                    // id="email"
                    // name="email"
                    name="email"
                    value={user.email}
                    // value={jarahlist.name}
                    onChange={(e)=>handleChangee(e,user)}
                  /> */}
          {/* <input
                    name="name"
                    value={jarahlist.name}
                    onChange={(e)=>handleChangee(e,jarahlist,setJarahlist)}
                    placeholder="name"
                  /> */}
          {/* <Inputfield
                    placeholder={"ইমামের নাম"}
                    name="name"
                    value={jarahlist.name}
                    fun={handleChangee}
                  /> */}
                  
                  {/* <Inputfield
                    placeholder={"বক্তব্য"}
                    value={x.quote}
                    name="quote"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  /> */}
                  {/* <Inputfield
                  placeholder={"আরবী"}
                  value={jarahlist.quote.arabic}
                  // name="quote.arabic"
                  fun={(e) => {
                    setJarahlist({
                      ...jarahlist,
                      quote: {
                        ...jarahlist.quote,
                        arabic: e.target.value,
                      },
                    });
                  }}
                />
                <Inputfield
                    placeholder={"বাংলা"}
                    value={jarahlist.quote.bangla}
                 
                    fun={(e) => {
                      setJarahlist({
                        ...jarahlist,
                        quote: {
                          ...jarahlist.quote,
                          bangla: e.target.value,
                        },
                      });
                    }}
                  /> */}

                  {/* <Inputfield
                    placeholder={"রেফারেন্স"}
                    value={jarahlist.ref}
                    name="ref"
                    fun={handleChangee}
                  />
                  <Inputfield
                    placeholder={"মান"}
                    value={jarahlist.grade}
                    name="grade"
                    fun={handleChangee}
                  /> */}



          {/* {jarahlist.map((x, i) => {
            return (
              <div key={i} className="box first-letter">
                <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    name="name"
                    value={x.name}
                    fun={handleChangee}
                  />
                  
                   <Inputfield
                    placeholder={"বক্তব্য"}
                    value={x.quote}
                    name="quote"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  />
                  <Inputfield
                  placeholder={"আরবী"}
                  value={x.quote.arabic}
                  // name="quote.arabic"
                  fun={(e) => {
                    setJarahlist({
                      ...x,
                      quote: {
                        ...x.quote,
                        arabic: e.target.value,
                      },
                    });
                  }}
                />
                <Inputfield
                    placeholder={"বাংলা"}
                    value={x.quote.bangla}
                 
                    fun={(e) => {
                      setJarahlist({
                        ...x,
                        quote: {
                          ...x.quote,
                          bangla: e.target.value,
                        },
                      });
                    }}
                  />

                  <Inputfield
                    placeholder={"রেফারেন্স"}
                    value={x.ref}
                    name="ref"
                    fun={handleChangee}
                  />
                  <Inputfield
                    placeholder={"মান"}
                    value={x.grade}
                    name="grade"
                    fun={handleChangee}
                  />
                  <input
                    id="email"
                    name="email"
                    value={user.email}
                    onChange={handleChangee}
                  />
                  <input
                    id="password"
                    name="password"
                    value={user.password}
                    onChange={handleChangee}
                  />
                  <input
                    type="text"
                    // checked={user.settings.subscribe}
                    value={user.settings.subscribe}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        settings: {
                          ...user.settings,
                          subscribe: e.target.value,
                        },
                      });
                    }}
                  />{" "}
                  Subscribe to the newsletter
                </div>

                <div className="btn-box">
                  {jarahlist.length !== 1 && (
                    <button
                      className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleRemoveClick(jarahlist, setJarahlist, i)
                      }
                    >
                      Remove
                    </button>
                  )}
                  {jarahlist.length - 1 === i && (
                    <button
                      className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                      onClick={() =>
                        handleAddClick(jarahlist, setJarahlist, {
                          name: "",
                          quote: "",
                          ref: "",
                        })
                      }
                    >
                      Add
                    </button>
                  )}
                </div>
              </div> 
             ); 
           })} 
        </div> */}
      {/* </div> */} 



      <div className="mb-5 download link">
        <Inputtitle title={"ডাউনলোড লিংক"} />
        <Inputfield placeholder={"ডাউনলোড লিংক"} />
      </div>
      <div className="mb-5 description section">
        <Inputtitle title={"রাবী সম্পর্কে বিস্তারিত আলোচনা"} />

        <textarea rows={10} className="w-full border border-black"></textarea>
      </div>
      <div className="mb-5 description section">
        <Inputtitle title={"রাবী সম্পর্কে কিছু বিশেষ কথা"} />

        <textarea rows={10} className="w-full border border-black"></textarea>
      </div>

      </div>
      
      
    </div>
  );
};

export default Testrajul;
