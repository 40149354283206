import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';

const Hero = () => {
  return (
    <div>
            <div className="px-2 md:pt-5 ayat md:px-6 ">
                <motion.div className="motion"
                initial={{
                    x:'130%',

                }}
                animate={{
                    x:'0'
        
                }}
                transition={{
                    delay:0.7
                }}
                >
                <p className='font-[LPMQIsepMisbah] text-[1.1rem] md:text-[2rem] mb-4 text-right'>يٰٓاَيُّهَا الَّذِيْنَ اٰمَنُوْٓا اِنْ جَاۤءَكُمْ فَاسِقٌۢ بِنَبَاٍ فَتَبَيَّنُوْٓا &#x6DD;</p>

                </motion.div>
            <motion.div className="m"
            initial={{
                x:'-130%',

            }}
            animate={{
                x:'0'
    
            }}
            transition={{
                delay:0.7
            }}
            
            >
            <p className='text-[1.1rem] md:text-[1.5rem]'>হে ঈমানদারগণ! যখন তোমাদের নিকট কোন ফাসিক খবর নিয়ে আসে তখন তা যাচাই করো।</p>
            <p className='ref w-fit bg-theme text-white p-[2px_11px] rounded-[3px] text-[0.8rem] md:text-[1.1rem]'>হুজুরাত: ০৬।</p>

            </motion.div>
            
            </div>
        
        <div className="flex flex-col-reverse w-full p-2 md:flex-row md:px-24 md:py-14 ">
            
        
        <motion.div className="flex-[3] col1"
        initial={{
            x:'-130%',
            


        }}
        animate={{
            x:'0'

        }}
        transition={{
            delay:0.7
        }}
        >
            <p className='text-4xl '>আহলে</p>
            <p className=' text-7xl md:text-9xl'>তাহকিক</p>
            <TypeAnimation
sequence={[1500,'একটি শারঈ তথ্য গবেষণা সংস্থা।', 1000, 'একটি শারঈ তথ্য গবেষণা সংস্থা।']}
speed={40} 
className={'  text-2xl'}
wrapper="h2"
repeat={0}
/>


        </motion.div>
        <div className="flex flex-col items-center flex-1 gap-2 col2">
           
            <motion.div className="image"
            initial={{
                opacity:0,
                scale:0.8
            }}
            animate={{
                opacity:1,
                scale:1

            }}
            transition={{
                duration:0.3,
                ease:'easeInOut'
            }}
            >
                {/* <img className='rounded-[20px] border-white border-2 w-[400px]' src="https://pbs.twimg.com/profile_images/1457583548404170755/Y7Lw5HFf_400x400.jpg" alt="rahul" /> */}
                <FontAwesomeIcon className='md:text-[200px] text-theme text-[118px]' icon="fa-solid fa-book-open-reader" />
                

            </motion.div>
            <div className="socialandonate w-fit">
                {/* <p className='font-[roboto]'>Join Us</p> */}
                <div className="icons flex gap-2 px-3 text-2xl rounded mb-3 shadow-[0px_2px_2px_0px_#f5f5f5]">
                    <a className='hover:text-slate-600' target='_blank' href="https://t.me/ahletahqeeq"><FontAwesomeIcon icon="fa-brands fa-telegram" /></a>
                    <a className='hover:text-slate-600' target='_blank' href="https://t.me/ahletahqeeq"><FontAwesomeIcon icon="fa-brands fa-facebook" /> </a>
                    <a  className='hover:text-slate-600'target='_blank' href="https://t.me/ahletahqeeq"><FontAwesomeIcon icon="fa-brands fa-discord" /> </a>
                </div>

            </div>

        </div>
    </div>

        </div>
  )
}

export default Hero