import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
const SearchItem = ({handelsearch, placeholder, searchkey}) => {
  const navigate = useNavigate();

  const searchandler=()=>{
if(searchkey){
  navigate(`?search=${searchkey}`);

}
else{
  toast.error('errror search input')

}

  }

  return (
    <>
    <div className="bg-[#ffffff] flex shadow-[-1px_0px_6px_2px_#f3f3f3] md:text-[18px] p-[8px_8px_8px_15px] rounded-[4px]  relative border-b-[3px] border-theme drop-shadow-[#373737_1px_-4px_7px_0px] ">
    <input
        type="text"
        placeholder={placeholder}
        className="w-full outline-none searchbar"
        onChange={handelsearch}
        
      
      />

      {/* <Link   to={`?search=${search}`}> */}
      <div onClick={searchandler}  className="icon bg-theme hover:text-[#a0a0a0] text-white hover:bg-[#e5e5e5] px-[13px] py-[6px] rounded-full h-[35px] w-[35px] flex justify-center items-center "> <FontAwesomeIcon className='' icon={faMagnifyingGlass} />


</div>
{/* </Link> */}

        
    </div>
    
      
    </>
  )
}

export default SearchItem